import React from 'react';
import {useDispatch} from 'react-redux';
import { UPDATE_ORG_CHART } from '../../store/actions';
import DivisionForm from './DivisionForm';
import { toast } from 'react-toastify';
import userService from '../../services/userService';

export default function EditDivision({ division, positionChart, createDiv, setData, callbackFunc }) {
    const dispatch = useDispatch();
    const handleSubmit = async (formData) => {
        const data = await userService.updateDivision(division.id, formData);
        if (data) {
            await typeof setData === 'function' && setData(data);
            await toast.success('Division/Dept updated successfully!');
            // trigger update of chart when the division details have been updated
            dispatch({ type: UPDATE_ORG_CHART});
        }
    };

    const handleCreate = async (formData) => {
        const data = await userService.createDivision(formData);
        if (data) {
            console.log({setData})
            typeof setData === 'function' && setData(data);
            toast.success('Division/Dept created successfully!');
            // trigger update of chart when the division details have been updated
            dispatch({ type: UPDATE_ORG_CHART});
        }
    };

    return <DivisionForm instance={division} callbackFunc={callbackFunc} handleSubmit={createDiv ? handleCreate : handleSubmit} positionChart={positionChart} />;
}

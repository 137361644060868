import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SelectActiveFeature from '../../../components/Settings/sections/SelectActiveFeatures';
import companyService from '../../../services/companyService';

export default function ActiveFeatures({ callbackFunc }) {
    const staff = useSelector((state) => state.account.user);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(true);

    const getCompanyInfo = async (showLoader = true) => {
        showLoader && setLoadingCompanyInfo(true);
        const data = await companyService.getCompany(staff?.company?.id);
        if (data) {
            setCompanyInfo(data);
        }
        setLoadingCompanyInfo(false);
    };

    const updateCallback = async () => {
        await getCompanyInfo(false);
        // typeof callbackFunc == 'function' && callbackFunc();
    };

    useEffect(() => {
        getCompanyInfo();
    }, []);

    if (loadingCompanyInfo) {
        return (
            <div className="text-center">
                Loading <Spinner animation="border" className="p-3 my-5" />
            </div>
        );
    }
    return (
        <SelectActiveFeature
            activeApps={companyInfo?.active_app}
            loadingCompanyInfo={loadingCompanyInfo}
            updateCompanyCallback={updateCallback}
        />
    );
}

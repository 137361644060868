import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import SelectActiveFeature from '../../../components/Settings/sections/SelectActiveFeatures';
import { ConfigContext } from '../../../contexts/ConfigContext';
import { useDispatch } from '../../../store';
import { setUser } from '../../../store/accountActions';
import { setCompany } from '../../../store/companyActions';

const ActiveAppsModal = () => {
    const activeApps = useSelector((state) => state.account.user.company.active_app);
    const [showModal, setShowModal] = useState(false);
    const [waitToSend, setWaitToSend] = useState(false);
    const dispatch = useDispatch();
    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;

    //this arrow function has some extra stuff in here so that the menu and subsequent reload of this
    // function is only done on hide.
    const handleCloseModal = () => {
        setShowModal(false);
        if (waitToSend) {
            dispatch(setUser(waitToSend.userCopy));
            dispatch(setCompany(waitToSend.data));
        }
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    return (
        <>
            <span className="b-title">
                {
                    <Button className="btn btn-secondary btn-sm mt-4 float-left " onClick={handleShowModal}>
                        Enable Features
                    </Button>
                }
            </span>
            <Modal show={showModal} backdrop="static" onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Active Features</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showModal && (
                        <SelectActiveFeature
                            activeApps={activeApps}
                            hideHeader={true}
                            waitToSend={setWaitToSend}
                            // loadingCompanyInfo={loadingCompanyInfo}
                            // updateCompanyCallback={updateCallback}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ActiveAppsModal;

import React, { useState } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Stats } from '../../../services';
import { useSelector } from 'react-redux';
import DivisionForm from '../../division/DivisionForm';

const AddDivision = ({ updateDivisions, selectDivision }) => {
    const [modalShow, setModalShow] = useState(false);
    const companyId = useSelector((state) => state.account?.user?.company?.id);

    const handleCloseModal = () => {
        setModalShow(false);
    };
    const handleShowModal = () => setModalShow(true);

    const handleAdd = async (formData) => {
        const data = await Stats.addDivision(companyId, formData);

        if (data) {
            selectDivision(data.id);
            handleCloseModal();
            updateDivisions(data);
        }
        handleCloseModal();
    };

    return (
        <>
            <OverlayTrigger placement="bottom" overlay={<Tooltip>Create New Division/Dept</Tooltip>}>
                {/*change style of this to be just the icon*/}
                <Button onClick={handleShowModal} className="btn btn-sm btn-icon ml-2 mr-0 ">
                    <i className="feather icon-plus fa-lg mx-0" />
                </Button>
            </OverlayTrigger>

            <Modal backdrop="static" show={modalShow} onHide={handleCloseModal} aria-labelledby="contained-modal-title-vcenter">
                {modalShow && (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h5>Add Division/Dept</h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <DivisionForm handleSubmit={handleAdd} />
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </>
    );
};

export default AddDivision;

import { API_SERVER } from '../config/constant';
import http, { serializeQuery } from './httpService';

export const getActionsList = async (queryParamsDict) => {
    const query = queryParamsDict ? serializeQuery(queryParamsDict) : '';
    try {
        const { data } = await http.get(`${API_SERVER}actions/api/actions/?${query}`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};


export const postToFrontEndErrors = async (object) => {
    try {
        const { data } = await http.post(`${API_SERVER}actions/api/log-frontend-error/`,object, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getActionsList
};

import chroma from 'chroma-js';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import divisionService from '../../../services/divisionService';
import userService from '../../../services/userService';
import { formatToReactSelectOption, formatToReactSelectOptionWithColor } from '../../../utils/common';

export default function DivisionMultiSelectInput({
    value,
    defaultValue,
    handleInputChange,
    label,
    name,
    disabled = false,
    enableOptionsColor = false,
    getDivisionsWithEmployees = false
}) {
    const [divisions, setDivisions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(value);

    const colourStylesMulti = {
        control: (styles) => ({ ...styles, backgroundColor: '#F4F7FA', borderRadius: '0.25rem' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = data.color ? chroma(data.color) : chroma('#333333');
            return {
                ...styles,
                backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : color.darken(0.9).css(),
                cursor: isDisabled ? 'not-allowed' : 'default'
            };
        },
        multiValue: (styles, { data }) => {
            const color = data.color ? chroma(data.color) : chroma('#333333');
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css()
            };
        },
        multiValueLabel: (styles, { data }) => {
            const color = data.color ? chroma(data.color) : chroma('#333333');
            return {
                ...styles,
                color: color.darken(0.9).css()
            };
        },
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color ? data.color : '#333333',
            ':hover': {
                backgroundColor: data.color ? data.color : '#333333',
                color: 'white'
            }
        })
    };

    const fetchDivisions = async () => {
        if (divisions.length === 0) {
            setLoading(true);
            let data;
            if (getDivisionsWithEmployees) {
                data = await divisionService.getDivisionsWitEmployees();
            } else {
                data = await userService.getDivisions();
            }
            if (data) {
                let allDivisions = data;

                if (enableOptionsColor) {
                    // add color to divisions
                    const labelField = getDivisionsWithEmployees ? 'name' : 'division_name';
                    allDivisions = formatToReactSelectOptionWithColor(data, labelField, 'division_color');
                } else {
                    allDivisions = formatToReactSelectOption(data, 'division_name');
                }

                setDivisions(allDivisions);
                setLoading(false);
            }
        }
    };

    const handleChange = (data, options) => {
        if (typeof handleInputChange === 'function') {
            handleInputChange(data, options);
        }
        setSelected(data);
    };

    useEffect(() => {
        if (value && divisions.length > 0) {
            const selectedOptions = divisions.filter((opt) => value.includes(opt.id));
            setSelected(selectedOptions);
        }
    }, [value, divisions.length]);

    useEffect(() => {
        fetchDivisions();
    }, []);

    return (
        <Form.Group>
            <Form.Label>{label || 'Divisions/Depts'}</Form.Label>
            <div className="d-flex">
                <Select
                    className="basic-single w-100"
                    classNamePrefix="select"
                    defaultValue={defaultValue}
                    value={selected}
                    isDisabled={loading || disabled}
                    isLoading={loading}
                    placeholder={loading ? 'loading...' : 'Select Divisions/Depts'}
                    isClearable={true}
                    isMulti
                    isSearchable={true}
                    name={name || 'division'}
                    onChange={handleChange}
                    options={divisions}
                    styles={
                        enableOptionsColor
                            ? colourStylesMulti
                            : {
                                  control: (baseStyles) => ({
                                      ...baseStyles
                                  })
                              }
                    }
                />
            </div>
        </Form.Group>
    );
}

import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import ScreenCapture from '@uppy/screen-capture';
import Webcam from '@uppy/webcam';
import React, { useEffect, useMemo } from 'react';

// Don't forget the CSS: core and the UI components + plugins you are using.
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
// import GoldenRetriever from '@uppy/golden-retriever';
import '@uppy/screen-capture/dist/style.min.css';
import '@uppy/webcam/dist/style.min.css';
import knowledgeService from '../../services/knowledgeService';
// Don’t forget to keep the Uppy instance outside your component.
function uppyInstance(allTypes) {
    const uppy = new Uppy({
        allowMultipleUploadBatches: allTypes, // set dynamically based on allTypes
        restrictions: {
            maxNumberOfFiles: allTypes ? null : 1, // if allTypes is true, no limit on number of files
            minNumberOfFiles: 1,
            allowedFileTypes: allTypes ? ['*/*'] : ['video/*'],
            maxFileSize: 3000000000 // 3GB
        }
    })
    if (!allTypes) {
        uppy.use(Webcam, {
            showVideoSourceDropdown: true,
            showRecordingLength: true,
            preferredVideoMimeType: 'video/mp4',
            modes: ['video-audio']
        })
        uppy.use(ScreenCapture, {preferredVideoMimeType: 'video/mp4'})
    }
    uppy.use(AwsS3, {
        // shouldUseMultipart: (file) => file.size > 100 * 2 ** 20,
        // companionUrl: 'http://127.0.0.1:8000/knowledgebase/api/',
        // companionHeaders: companionHeaders.headers,
        getUploadParameters(file) {
            console.log('Getting upload parameters for file ::', file.name);
            console.log('the file is :::', file);

            let fileType = file.type;
            if (fileType.includes(';')) {
                fileType = fileType.substring(0, fileType.indexOf(';'));
            }

            const data = {
                file_name: file.name,
                file_content_type: fileType
            };

            return knowledgeService.generateS3PresignedUrl(data).then((resData) => {
                // Return an object in the correct shape.
                console.log('Got upload parameters for file', file.name);

                if (resData) {
                    uppy.setFileState(file.id, { uploadUrl: resData.url.substring(0, resData.url.indexOf('?X-Amz-Algorithm')) });
                    return {
                        method: resData.method,
                        url: resData.url,
                        fields: [],
                        expires: resData.expires_in,
                        headers: { 'Content-Type': resData.content_type }
                    };
                }
                return {};
            });
        }
    });
    // .use(GoldenRetriever, { serviceWorker: true });

    return uppy;
}

export default function KBArticleMediaRecorder({ setVideo, small, allTypes, setOnDeck }) {
    const uppy = useMemo(() => uppyInstance(allTypes), [allTypes]);

    uppy.on('file-added', () => {
        console.log('file added');
        typeof setOnDeck === 'function' && setOnDeck(true);
    });

    uppy.on('dashboard:show-panel', () => {
        console.log('file a');
        typeof setOnDeck === 'function' && setOnDeck(true);
    });



    uppy.on('file-removed', () => {
        typeof setOnDeck === 'function' && setOnDeck(false);
    });

    uppy.on('complete', function (result) {
        console.log('complete upload ::::::::', result);
        const urls = result.successful?.map((file) => file.uploadUrl);
        typeof setVideo === 'function' && setVideo(urls);
        uppy.cancelAll({ reason: 'user' });
        typeof setOnDeck === 'function' && setOnDeck(false);
    });

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register(`${process.env.PUBLIC_URL}/service-worker.js`) // path to your bundled service worker with GoldenRetriever service worker
                .then((registration) => {
                    console.log('ServiceWorker registration successful with scope: ', registration.scope);
                    // uppy.log('[GoldenRetriever] Restore confirmed, proceeding...');
                    // // start all uploads again when file blobs are restored
                    // const { currentUploads } = uppy.getState();

                    // console.log('current uploads :::::', currentUploads);
                    // if (currentUploads) {
                    //     Object.keys(currentUploads).forEach((uploadId) => {
                    //         uppy.restore(uploadId);
                    //     });
                    //     uppy.resumeAll();
                    // }
                    // uppy.setState({ recoveredState: null });
                })
                .catch((error) => {
                    console.log('Registration failed with ' + error);
                });
        }
    }, [uppy]);

    // useEffect(() => {
    //     return () => uppy.close();
    // }, []);



    return (
        <div style={{ width: '100%' }}>
            <Dashboard
                {...(small ? { height: 200 } : {})}
                width="100%" uppy={uppy}
                autoProceed={true}
                plugins={['Webcam', 'ScreenCapture']}
            />
        </div>
    );
}

// import Uppy from '@uppy/core';
// import Dashboard from '@uppy/dashboard';
// import Webcam from '@uppy/webcam';
// import ScreenCapture from '@uppy/screen-capture';

// import '@uppy/core/dist/style.min.css';
// import '@uppy/dashboard/dist/style.min.css';
// import '@uppy/webcam/dist/style.min.css';
// import '@uppy/screen-capture/dist/style.min.css';

// export default function KBArticleMediaRecorder() {
//     const uppy = new Uppy()
//     .use(Dashboard, { inline: true, target: 'body' })
//     .use(Webcam, { target: Dashboard, showVideoSourceDropdown: true,
//         showRecordingLength: true })
//     .use(ScreenCapture, { target: Dashboard });

//     // const uppy = new Uppy().use(Webcam);

//     useEffect(() => {
//         return () => uppy.close();
//     }, []);

//     return <></>;
// }

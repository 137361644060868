import http from './httpService'
import { API_SERVER } from '../config/constant';
const fileUploadUrl='/statistics/api/upload/'

const uploadFile=async(id,file)=>{
    try {
       const {data}= await http.post(`${API_SERVER}${fileUploadUrl}${id}/`,file,http.tokenConfigFile())
        return data
    } catch (error) {
        console.log(error)
        return
    }
}

export default{
    uploadFile
}
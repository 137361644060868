import moment from 'moment';
import { toast } from 'react-toastify';

export const getDaysArray = (start, end) => {
    // Generates a list of dates that fall in the range given
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt).toISOString().split('T')[0]);
    }
    return arr;
};

export function arraysOfItemsEqual(a, b) {
    // Returns true or false if arrays are equal or not

    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export const objectsEqual = (o1, o2) => Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => o1[p] === o2[p]);

export const arrayOfObjectsEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const getUserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getDayNumber = (dayName) => {
    const dayNameLower = dayName.toLowerCase();
    const days = { monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6, sunday: 7 };
    return days[dayNameLower];
};

export const formatToReactSelectOptionWithColor = (dataList, labelField, colorField) => {
    const finalData = dataList?.map((item) => ({
        id: item?.id,
        value: item?.id,
        label: item && item[labelField],
        color: item && item[colorField]
    }));

    finalData.sort((a, b) => a.label?.localeCompare(b.label));

    return finalData;
};

export const formatToReactSelectOption = (dataList, labelField) => {
    const finalData = dataList?.map((item) => ({
        id: item?.id,
        value: item?.id,
        label: item && item[labelField]
    }));

    finalData.sort((a, b) => a.label?.localeCompare(b.label));

    return finalData;
};

export const getListOfIds = (items) => {
    // Returns list of ids for the items given
    let dataArr = [];
    items?.map((item) => {
        dataArr.push(item.id);
    });
    return dataArr;
};

export const sortListOfObjectsAlphabetically = (items, key) => {
    // Sorts the list of objects given alphabetically, the 'key' is
    // the field to use to sort by
    const sortedItems = items.sort(function (a, b) {
        var textA = a[key].toUpperCase();
        var textB = b[key].toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return sortedItems;
};

export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
}

export function slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
    str = str.toLowerCase(); // convert string to lowercase
    str = str
        .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
    return str;
}

export const ConvertStringIdToInt = (stringId) => {
    // our node ids contain letter n e.g. for id 2 it is n2
    // we get only the number part of it
    return parseInt(stringId.replace('n', ''));
};

export const aGgridSortModelDataToQueryParams = (sortModelData) => {
    if (!sortModelData || sortModelData.length === 0) {
        return '';
    }
    // Access the last sort model
    const lastSortModel = sortModelData[0];
    let ordering = lastSortModel.sort === 'desc' ? `-${lastSortModel.colId}` : lastSortModel.colId;
    ordering = ordering.replace('_info', '');
    ordering = ordering.replace('.', '__');
    return ordering;
};

export const timestampFormatter = (timestamp) => {
    // console.log({timestamp})
    return timestamp ? moment(timestamp).format('MMM. DD YYYY') : '';
};

export const toShortDateFormat = (dateTime, format) => {
    return dateTime ? moment(dateTime, 'YYYY-MM-DD').format(format || 'll') : 'None';
};

export const toDateTimeFormat = (dateTime, format) => {
    return dateTime ? moment(dateTime, format || 'MM-DD-YYYY HH:mm:ss').format('lll') : '';
};

export const copyCurrentUrlPathLink = () => {
    let url = document.location.href;

    navigator.clipboard.writeText(url).then(function () {
        toast.success('Link Copied');
    });
};

export const copyGivenValue = (value) => {
    navigator.clipboard.writeText(value).then(function () {
        toast.success('Copied!');
    });
};

export function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach((key) => {
            // if is not an array index (a number), we add it as it is.
            let keyVar;
            if (!isNaN(key)) {
                // if it is an array index, we add it as field[0], field[1] etc.
                keyVar = `[${key}]`;
            } else {
                if (Array.isArray(data[key])) {
                    // if the key value is an array.
                    if (data[key].length > 0) {
                        if (typeof data[key][0] === 'object' && Array.isArray(data[key][0]) === false) {
                            // if dealing with an array but it is an array of objects, we append the
                            // property as field.property
                            keyVar = `.${key}`;
                        } else {
                            // if dealing with an array of primitive data types (number, string, bool etc)
                            // we just append the items as it is.
                            data[key].forEach((item) => formData.append(key, item));
                            return;
                        }
                    }
                } else if (typeof data[key] === 'object' && Array.isArray(data[key]) === false) {
                    // if the key value is a dictionary
                    keyVar = `.${key}`;
                } else {
                    // if key value is of primitive data types (number, string, bool etc)
                    keyVar = key;
                }
            }

            return buildFormData(formData, data[key], parentKey ? `${parentKey}${keyVar}` : key);
        });
    } else {
        // if key value is of primitive data types (number, string, bool etc) or file
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

export function buildCompanyFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach((key) => {
            // if is not an array index (a number), we add it as it is.
            let keyVar;
            if (!isNaN(key)) {
                // if it is an array index, we add it as field[0], field[1] etc.
                keyVar = `[${key}]`;
            } else {
                if (Array.isArray(data[key])) {
                    // if the key value is an array.
                    if (data[key].length > 0) {
                        if (typeof data[key][0] === 'object' && Array.isArray(data[key][0]) === false) {
                            // if dealing with an array but it is an array of objects, we append the
                            // property as field.property
                            keyVar = `.${key}`;
                        } else {
                            // if dealing with an array of primitive data types (number, string, bool etc)
                            // we just append the items as it is.
                            data[key].forEach((item) => formData.append(key, item));
                            return;
                        }
                    } else {
                        formData.append(`${key}`, []);
                    }
                } else if (typeof data[key] === 'object' && Array.isArray(data[key]) === false) {
                    // if the key value is a dictionary
                    keyVar = `.${key}`;
                } else {
                    // if key value is of primitive data types (number, string, bool etc)
                    keyVar = key;
                }
            }

            return buildFormData(formData, data[key], parentKey ? `${parentKey}${keyVar}` : key);
        });
    } else {
        // if key value is of primitive data types (number, string, bool etc) or file
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

export const dictHasItems = (dict) => {
    // Returns true if a dictionary is not empty
    return Object.keys(dict).length > 0;
};

export function findIndexByPropertyValue(list, propertyName, propertyValue) {
    /* Usage example:

    let list = [
        { id: 1, name: 'John' },
        { id: 2, name: 'Jane' },
        { id: 3, name: 'Bob' }
      ];
      
      let propertyName = 'id';
      let propertyValue = 2;
      
      let index = findIndexByPropertyValue(list, propertyName, propertyValue);
      
      console.log(index); // Output: 1

    */
    for (let i = 0; i < list.length; i++) {
        if (list[i][propertyName] === propertyValue) {
            return i;
        }
    }
    return -1; // Return -1 if not found
}

export function replaceDictByPropertyValue(list, propertyName, propertyValue, newDict) {
    // replace a dict by property value in a list of dicts
    let index = findIndexByPropertyValue(list, propertyName, propertyValue);
    if (index !== -1) {
        list[index] = newDict;
    } else {
        console.error('Property value not found');
        return;
    }
    return list;
}

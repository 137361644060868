import { Field, Form, useField, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import * as Yup from 'yup';
import CreatePositionModal from '../../../components/Positions/CreatePositionModal';
import userService from '../../../services/userService';
import InputMask from 'react-input-mask';

export default function PersonalInfo({ submitBtnRef }) {
    const staff = useSelector((state) => state.account.user);
    const [user, setUser] = useState(staff || {});
    const [companyPositions, setCompanyPositions] = useState([]);
    const [loadingPositions, setLoadingPositions] = useState(false);
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);

    useEffect(() => {
        getUserDetails();
        getCompanyPositions();
    }, []);

    const formatPositionOptions = (options) => {
        return options?.map((position) => ({
            value: position.id,
            label: position.name,
            isPrimary: staff?.position === position.id
        }));
    };

    const getCompanyPositions = async () => {
        setLoadingPositions(true);
        const data = await userService.getPositions({ simple_details: true });
        if (data) {
            const positionOptions = formatPositionOptions(data);
            setCompanyPositions(positionOptions);
        }
        setLoadingPositions(false);
    };

    const getUserDetails = async () => {
        setLoadingUserInfo(true);
        const data = await userService.getProfile(staff?.id);
        if (data) {
            const userPositions = data.positions || [];
            const cleanedUser = {
                ...data,
                first_name: data.first_name || '',
                last_name: data.last_name || '',
                personal_phone: data.personal_phone || '',
                user_type: data.user_type?.id,
                positions: formatPositionOptions(userPositions),
                staff_division: data.staff_division?.id
            };
            setUser(cleanedUser);
        }
        setLoadingUserInfo(false);
    };

    const Schema = Yup.object().shape({
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string()
    });

    const onSubmit = async (formData, actions) => {
        let createData = {
            ...formData,
            positions: formData.positions?.map((position) => position.value),
            position: formData.positions.find((option) => option.isPrimary)?.value || null
        };

        let data = await userService.updateStaff(staff?.id, createData);

        if (data) {
            // call the callback function if it exists
            // typeof callbackFunc === 'function' && callbackFunc();
        }
        // update submit to false
        actions.setSubmitting(false);
    };

    const handlePositionChange = (options, setFieldValue) => {
        let updatedOptions = [...options];
        if (updatedOptions.length > 0) {
            // Assign 'primary' field to false for all options
            updatedOptions = options?.map((option) => ({
                ...option,
                isPrimary: false
            }));

            // If no option is marked as primary, set the first one as primary
            if (!updatedOptions.find((option) => option.isPrimary)) {
                updatedOptions[0].isPrimary = true;

                setFieldValue('position', updatedOptions[0].value);
            }
            // setCompanyPositions(updatedOptions);
        }
        setFieldValue('positions', updatedOptions);
    };

    const MultiValueLabel = (props) => {
        return (
            <components.MultiValueLabel {...props}>
                {/* Show '(primary)' for primary option */}
                {/* {JSON.stringify(props.data)} '----------------' */}
                {props.data.label} {props.data.isPrimary ? <span className="text-primary">(Primary)</span> : ''}
            </components.MultiValueLabel>
        );
    };

    const addPositionCallbackFunc = (newPosition, setFieldValue, values) => {
        const newAddedPosition = formatPositionOptions([newPosition]);
        const finalPositions = [...newAddedPosition, ...companyPositions];
        setCompanyPositions(finalPositions);

        // Update Formik's positions field directly instead of local user state
        setFieldValue('positions', [...values.positions, ...newAddedPosition]);
    };

    const PhoneNumberInput = ({ ...props }) => {
        const [field, meta] = useField(props);
        return (
            <div>
                <label htmlFor={props.id || props.name}>Phone Number</label>
                <InputMask {...field} {...props} mask="(999) 999-9999" maskChar=" ">
                    {(inputProps) => <input className="form-control mb-2" {...inputProps} />}
                </InputMask>
                {meta.touched && meta.error ? (
                    <span className="text-danger">{meta.error}</span>
                ) : null}
            </div>
        );
    };

    return loadingUserInfo ? (
        <div className="text-center">
            <Spinner animation="border" className="p-3 my-5" />
        </div>
    ) : (
        <Formik
            validationSchema={Schema}
            initialValues={{
                ...user
            }}
            enableReinitialize={true}
            onSubmit={onSubmit}
        >
            {({ errors, values, isSubmitting, setFieldValue }) => (
                <Form>
                    <Row>
                        <Col md={6} sm={12}>
                            <label>First Name</label>
                            <Field type="text" name="first_name" className="form-control mb-2" />
                            {errors.first_name && <span className="text-danger">{errors.first_name}</span>}
                        </Col>

                        <Col md={6} sm={12}>
                            <label>Last Name</label>
                            <Field type="text" name="last_name" className="form-control mb-2" />
                            {errors.last_name && <span className="text-danger">{errors.last_name}</span>}
                        </Col>
                        <Col md={12} sm={12}>
                            <PhoneNumberInput name="personal_phone" />
                        </Col>


                    </Row>

                    <Row className={''}>
                        <Col sm={12}>
                            <label>Position(s)</label>
                            <Row noGutters>
                                <Col sm={12} md={11}>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isMulti
                                        options={companyPositions}
                                        name="positions"
                                        value={values?.positions}
                                        onChange={(data, _) => handlePositionChange(data, setFieldValue)}
                                        isDisabled={loadingPositions}
                                        isLoading={loadingPositions}
                                        isSearchable={true}
                                        components={{ MultiValueLabel }}
                                        closeMenuOnSelect={false}
                                    />
                                </Col>
                                <Col sm={12} md={1}>
                                    <CreatePositionModal
                                        type="simple"
                                        callbackFunc={(newPosition) => addPositionCallbackFunc(newPosition, setFieldValue, values)}
                                        className={'p-1'}
                                    />
                                </Col>
                            </Row>

                            <small>The first position will be marked as primary position.</small>
                        </Col>
                    </Row>

                    <Button
                        type="submit"
                        ref={submitBtnRef}
                        className="btn-primary mt-3 w-100"
                        disabled={isSubmitting}
                        style={{ display: 'none' }}
                    >
                        {isSubmitting ? <Spinner animation="border" /> : 'Submit'}
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

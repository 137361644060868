import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';

import navigation from '../../../menu-items';
import { BASE_TITLE, BASENAME } from '../../../config/constant';
import getMenuItems from "../../../menu-items";
import {useSelector} from "../../../store";

const Breadcrumb = () => {
    const [main, setMain] = useState([]);
    const [item, setItem] = useState([]);
    const location = useLocation(); // Get the current location

    const user = useSelector((state) => state.account.user);

    const menuItems = getMenuItems(user);




    useEffect(() => {
        menuItems.items?.map((item, index) => {
            if (item.type && item.type === 'group') {
                getCollapse(item, index);
            }
            return false;
        });
    },[user,location]);

    const getCollapse = (item, index) => {
    if (item.children) {
        item.children.filter((collapse) => {
            console.log({collapse})
            if (collapse.type && collapse.type === 'collapse') {
                getCollapse(collapse, index);
            } else if (collapse.type && (collapse.type === 'item' || collapse.type === 'hidden')) {
                // Replace :id in the URL with a regex pattern to match any alphanumeric sequence
                if (collapse.url === '/') {
                        setMain(item);
                        setItem(collapse);
                } else {
                    // console.log({BASENAME},collapse.url,document.location.pathname,(collapse.type === 'item' || collapse.type === 'hidden'))
                    const urlPattern = new RegExp("^" + BASENAME + collapse.url.replace(/:id/g, '[^/]+') + "$");
                    if (urlPattern.test(document.location.pathname)) {
                        setMain(item);
                        setItem(collapse);
                    }
                }
            }
            return false;
        });
    }
};

    let mainContent, itemContent;
    let breadcrumbContent = '';
    let title = '';
    // console.log({main,item})

    if (main && main.type === 'collapse') {
        mainContent = (
            <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
                <Link to={main.url}>{main.title}</Link>
            </ListGroup.Item>
        );
    }

    if (item && (item.type === 'item'|| item.type === 'hidden')) {
        title = item.title;
        itemContent = (
            <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
                <Link to={item.url}>{title}</Link>
            </ListGroup.Item>
        );

        if (item.breadcrumbs !== false) {
            breadcrumbContent = (
                <div className="page-header">
                    <div className="page-block">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <div className="page-header-title">
                                    <h5 className="m-b-10">{title}</h5>
                                </div>
                                <ListGroup as="ul" bsPrefix=" " className="breadcrumb">
                                    <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
                                        <Link to="/">
                                            <i className="feather icon-home" />
                                        </Link>
                                    </ListGroup.Item>
                                    {mainContent}
                                    {itemContent}
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        document.title = title + BASE_TITLE;
    }

    return <React.Fragment>{breadcrumbContent}</React.Fragment>;
};

export default Breadcrumb;

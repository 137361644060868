import { SET_COURSE_FORM_ERRORS } from '../../store/actions';

export function arrayMove(arr, old_index, new_index) {
    // function to reorder the given array to update the
    // index of the old_index  to new index
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

export const getListOfIDs = (items) => {
    // utility function to return only a list ids given a list of
    // objects with each object having an id property
    let cleanedList = [];
    items?.map((item) => {
        cleanedList.push(item.id);
    });
    return cleanedList;
};

export const getCourseProgressionPercentage = (sectionItems) => {
    // we get the course progression
    const totalCourseArticles = sectionItems.length;
    const readItems = sectionItems.filter((item) => item.is_complete === true).length;

    let progressPercentage = 0;
    if (readItems > 0) {
        progressPercentage = Math.ceil((readItems / totalCourseArticles) * 100);
    }
    return progressPercentage;
};

export const validateCourseForm = (courseName, courseCategory, coursePriority, courseSections, dispatch) => {
    // Required fields are: courseName, courseCategory, coursePriority
    let isValid = true;
    let courseFormErrors = {
        courseName: '',
        courseCategory: '',
        coursePriority: '',
        courseSections: ''
    };
    if (!courseName) {
        courseFormErrors['courseName'] = 'error';
        isValid = false;
    }
    if (!courseCategory) {
        courseFormErrors['courseCategory'] = 'error';
        isValid = false;
    }
    if (!coursePriority) {
        courseFormErrors['coursePriority'] = 'error';
        isValid = false;
    }

    if (courseSections.length > 0) {
        // if course section doesn't have a title or articles we show an error
        for (var i = 0; i < courseSections.length; i++) {
            if (!courseSections[i].title) {
                isValid = false;
                courseFormErrors['courseSections'] = 'Each course section must have a title';
                break;
            }
            //commented out so people can create draft courses better
            // if (courseSections[i].articles.length == 0) {
            //     isValid = false;
            //     courseFormErrors['courseSections'] = 'Each course section should have at least one article';
            //     break;
            // }
        }
    } else {
        // if we have no course sections we raise an error
        isValid = false;
        courseFormErrors['courseSections'] = 'Course should have at least one section';
    }

    dispatch({ type: SET_COURSE_FORM_ERRORS, payload: courseFormErrors });

    return isValid;
};

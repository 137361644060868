import {
    GET_DIVISIONS,
    GET_MANAGERS,
    GET_POSITIONS,
    GET_USER_TYPES,
    ORG_CHART_UPDATED,
    SET_COMPANY,
    SET_CONFETTI,
    SET_FILE_TYPES,
    SET_HORIZONTAL_ORG_DATA,
    SET_ORG_DATA,
    SET_ORG_FLOW_EDIT,
    UPDATE_ORG_CHART,
    UPDATE_ORG_CHART_DIV
} from './actions';
const persistedOrgFlowEditMode = JSON.parse(localStorage.getItem('OrgFlowEditMode'));

export const initialState = {
    companyProfile: {},
    divisions: [],
    positions: [],
    managers: [],
    userTypes: [],
    updateOrgChart: false,
    orgChartUpdated: false,
    updateOrgChartDiv: true,
    OrgFlowEditMode: persistedOrgFlowEditMode !== null ? persistedOrgFlowEditMode : false,
    OrgFlowData: {
        highlights: true,
        outline: true,
        background: false,
        disable_div_tree: false,
        corner_style: true
    },
    HorizontalOrgData: {
        all_nodes_small: false,
        hide_emp_div_dept_title: false
    },
    fileTypes: []
};

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY:
            return {
                ...state,
                companyProfile: action.payload
            };

        case GET_DIVISIONS:
            return {
                ...state,
                divisions: action.payload
            };

        case GET_POSITIONS:
            return {
                ...state,
                positions: action.payload
            };

        case GET_MANAGERS:
            return {
                ...state,
                managers: action.payload
            };

        case GET_USER_TYPES:
            return {
                ...state,
                userTypes: action.payload
            };
        case UPDATE_ORG_CHART:
            return {
                ...state,
                updateOrgChart: !state.updateOrgChart
            };

        case ORG_CHART_UPDATED:
            return {
                ...state,
                orgChartUpdated: !state.orgChartUpdated
            };
        case UPDATE_ORG_CHART_DIV:
            return {
                ...state,
                updateOrgChartDiv: !state.updateOrgChart
            };
        case SET_ORG_FLOW_EDIT:
            localStorage.setItem('OrgFlowEditMode', JSON.stringify(action.payload));

            return {
                ...state,
                OrgFlowEditMode: action.payload
            };

        case SET_ORG_DATA:
            return {
                ...state,
                OrgFlowData: action.payload
            };
        case SET_HORIZONTAL_ORG_DATA:
            return {
                ...state,
                HorizontalOrgData: action.payload
            };

        case SET_CONFETTI:
            return {
                ...state,
                showConfetti: action.payload
            };
        case SET_FILE_TYPES:
            return {
                ...state,
                fileTypes: action.payload
            };

        default:
            return state;
    }
};

export default companyReducer;

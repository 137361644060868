import { useEffect } from 'react';
// import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalStorage';
import { ONBOARDING_CHOOSE_PLAN } from '../../views/auth/onboarding/Onboarding';

const SubscriptionGuard = ({ children }) => {
    const user = useSelector((state) => state.account.user);
    const [_, setValue] = useLocalStorage('onboardingActiveStep', 0);
    const history = useHistory();

    useEffect(() => {
        const onboardingPath = ['/auth/onboarding', '/auth/onboarding/'];
        if (user) {
            if ('has_subscription_plan' in user && !onboardingPath.includes(history.location.pathname)) {
                // Have to implement this check to avoid errors with already logged in users who
                // may not have this key fetched from the backend.
                if (!user.has_subscription_plan) {
                    // We redirect the user to complete selecting their active plan
                    setValue(ONBOARDING_CHOOSE_PLAN);
                    // <Redirect to="/auth/onboarding" />
                    history.push('/auth/onboarding');
                }
            }
        }
    }, []);

    return children;
};

export default SubscriptionGuard;

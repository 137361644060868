import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import EditDivision from './editDivision';
import {UPDATE_ORG_CHART} from "../../store/actions";
import {useDispatch} from "react-redux";


export const EditDivisionModal = ({ btnText, division, callBackFunc }) => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const callEditDivision = (data) => {
        // called when edit division is successful
        typeof callBackFunc === 'function' && callBackFunc(data);
        dispatch({ type: UPDATE_ORG_CHART});
        handleClose();
    };

    console.log('setData')

    return (
        <>
            <Button variant='primary' onClick={handleShow} className='btn btn-sm btn-icon ml-2 '>
                {btnText || 'Edit Division/Dept'}
            </Button>

            <Modal show={show} backdrop="static" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Division/Dept:{division?.division_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditDivision division={division} setData={callEditDivision} callbackFunc={handleClose} />
                </Modal.Body>

            </Modal>
        </>
    );
};
import React, {useRef, useState} from 'react';
import {Button, Card, Col, Modal, Row} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import image1 from '../../../../assets/images/share/Humanagement-Org-Chart.png';
import image2 from '../../../../assets/images/share/Humanagement-Stats.png';
import image3 from '../../../../assets/images/share/Humanagement-Training.png';
import image4 from '../../../../assets/images/share/Humanagement-Testimonial.png';
export default function NavShareModal() {
    const [show, setShow] = useState(false);
    const user = useSelector((state) => state.account.user);

    const cardBodyRef1 = useRef(null);
    const cardBodyRef2 = useRef(null);
    const cardBodyRef3 = useRef(null);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const downloadImage = (imageUrl) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'DownloadedImage'; // You can also dynamically generate the download filename based on your requirements
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const copyTextToClipboard = async (ref) => {
        if (ref && ref.current) {
            const text = ref.current.innerText;
            try {
                await navigator.clipboard.writeText(text);
                alert('Text copied to clipboard');
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        }
    };


    return (
        <>
            <span className="btn-link cursor-pointer"  onClick={handleShow}> Get Free Credits</span>
            <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Earn free credits that will save on your next bill with us!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/*<p>It’s official NOW! We ask your help.</p>*/}
                    <p>At {process.env.REACT_APP_NAME} we are expanding and decided to reward all of our brand advocates.</p>
                    <p>Simply sharing our website link with an image and a pre-written message
                        (or a message you wish to write) and we’ll credit a full $5 to your
                        account every time you share. Meaning just for each social media share,
                        you get billed less next month.</p>
                    <p>Additionally, will manually add another $30 credit to your account each
                        and every time some one signs up on a paid subscription with us thanks to your post.</p>
                    <p>Sounds like a sweet deal?</p>
                    <p>Simply Do The Following:</p>
                    <ol>
                        <li>Download one of the images</li>
                        <li>Copy one of the pre written post contents below(customize it as you want)</li>
                        <li>Click on the &ldquo;share&rdquo; button in this window at the bottom right corner</li>
                        <li>Paste the copied content (and edit it if needed) and upload the image</li>
                        <li>Post to social media</li>
                        <li>We get notified every time you click the &ldquo;share&rdquo; button on this window and we&rsquo;ll add the credits to your account.</li>
                        <li>We send you a confirmation about your new credits</li>
                    </ol>
                    <p>We really appreciate your help in putting a good word out!</p>
                    <hr />
                    <Row>
                        <Col  className='force-center'>
                            <div className="mb-4"><img
                                src={image1}
                                className="flex-wrap img-fluid"
                                style={{width: 'auto', height: '100%', objectFit: 'cover'}}
                                alt="User Profile Image"
                                onClick={() => downloadImage(image1)}
                            /></div>
                            <a href={image1} download style={{ textDecoration: 'none' }}>
                                <Button className='force-center mr-0'>Download</Button>
                            </a>
                        </Col>

                        <Col  className='force-center'>
                            <div className="mb-4"><img
                                src={image2}
                                className="flex-wrap img-fluid"
                                style={{width: 'auto', height: '100%', objectFit: 'cover'}}
                                alt="User Profile Image"
                                onClick={() => downloadImage(image2)}
                            /></div>
                            <a href={image2} download style={{ textDecoration: 'none' }}>
                                <Button className='force-center mr-0'>Download</Button>
                            </a>
                        </Col>
                        <Col  className='force-center'>
                            <div className="mb-4"><img
                                src={image3}
                                className="flex-wrap img-fluid"
                                style={{width: 'auto', height: '100%', objectFit: 'cover'}}
                                alt="User Profile Image"
                                onClick={() => downloadImage(image3)}
                            /></div>
                            <a href={image3} download style={{ textDecoration: 'none' }}>
                                <Button className='force-center mr-0'>Download</Button>
                            </a>
                        </Col>
                        <Col  className='force-center'>
                            <div className="mb-4"><img
                                src={image4}
                                className="flex-wrap img-fluid"
                                style={{width: 'auto', height: '100%', objectFit: 'cover'}}
                                alt="User Profile Image"
                                onClick={() => downloadImage(image4)}
                            /></div>
                            <a href={image4} download style={{ textDecoration: 'none' }}>
                                <Button className='force-center mr-0'>Download</Button>
                            </a>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body ref={cardBodyRef1}>
                                    Me and my organization are using {process.env.REACT_APP_NAME} !<br/>&nbsp;<br/>It is our top choice for managing:<br/>-Statistics&hellip;. with options for the following stats: cumulative, overlaying, viability line, inverted)<br/>-Organizational flow chart creation &amp; visualization<br/>-Company article writing, storage &amp; publication (for internal or external use)<br/>-Staff training courses<br/>-Writing &amp; approval of internal Purchase Orders<br/>-Junior to senior staff requests &amp; communications<br/>-Task assignments<br/>-Checklist creation, assignment &amp; supervision<br/>&nbsp;<br/>Now the team of humanagement accepts demo requests from everybody.<br/>I think you will find it as valuable as we did.<br/>They provide free demos here -&gt; <a href="https://humanagement.io/">https://humanagement.io/</a>
                                </Card.Body>
                                <Button id='socialorganization' onClick={() => copyTextToClipboard(cardBodyRef1)} className='mt-2  mr-0'>Copy Text</Button>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Card.Body ref={cardBodyRef2}>
                                    So far {process.env.REACT_APP_NAME} is what I found to be the complete system for:
                                    <ul>
                                        <li>Staff training & management</li>
                                        <li>Stats for anything in my organization with extensive flexibility</li>
                                        <li>Company operations</li>
                                    </ul>
                                    It is really the system that took our company to the next level, and I love to use it.
                                    <br /><br />
                                    If you haven’t heard of them before, they are currently doing free demos to everybody on this link -> <a href="https://humanagement.io" target="_blank" rel="noopener noreferrer">humanagement.io</a>
                                </Card.Body>
                                <Button id='socialcomplete' onClick={() => copyTextToClipboard(cardBodyRef2)} className='mt-2  mr-0'>Copy Text</Button>
                            </Card>
                        </Col>
                        {/*<Col>*/}
                        {/*    <Card>*/}
                        {/*        <Card.Body ref={cardBodyRef3}>*/}
                        {/*            I am using a special company management system that was created by just 1 person and his coding skills in _______, he is working on it ever since daily.*/}
                        {/*            <br /><br />*/}
                        {/*            It’s just amazing what someone can create with dedication.*/}
                        {/*            <br /><br />*/}
                        {/*            The system speeds up staff training, and organizational coordination with a set of tools like stats, organizational flowcharts, company training documentation, and more.*/}
                        {/*            <br /><br />*/}
                        {/*            They asked me to share a bit about them if I am satisfied, so here it is.*/}
                        {/*            <br /><br />*/}
                        {/*            If you got a second, take a look at <a href="https://humanagement.io" target="_blank" rel="noopener noreferrer">humanagement.io</a>*/}
                        {/*            <br /><br />*/}
                        {/*            Or request a demo on -> <a href="https://humanagement.io" target="_blank" rel="noopener noreferrer">humanagement.io</a>*/}
                        {/*        </Card.Body>*/}
                        {/*        <Button id='socialshare' onClick={() => copyTextToClipboard(cardBodyRef3)} className=' mt-2 mr-0'>Copy Text</Button>*/}
                        {/*    </Card>*/}
                        {/*</Col>*/}

                    </Row>

                  </Modal.Body>
                <Modal.Footer className='d-block'>
                    <span className='mr-0 small'>Note: The share credit is only valid for one share per week per user. Thank you for helping us!</span>
                    <Button id='socialshare' className="btn-success float-right" onClick={()=> window.open('https://www.facebook.com/sharer.php?u=https://humanagement.io', '_blank', 'noopener,noreferrer')}> Share</Button>

                    <Button className=' btn-secondary float-right' onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

import React from 'react';
import {useReactFlow} from "react-flow-renderer";

export default function FlowBar ({reactFlowInstance}) {


  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };


  // todo get this to work
  // const onClickAddNode = (nodeType) => {
  //   // Assuming you want to add the node to the center of the viewport
  //   const viewportCenter = reactFlowInstance.project({
  //     x: reactFlowInstance.getViewport().width / 2,
  //     y: reactFlowInstance.getViewport().height / 2,
  //   });
  //
  //   const newNode = {
  //     id: String(Math.random()), // Generate a unique ID for the new node
  //     type: nodeType,
  //     position: viewportCenter,
  //     data: { label: `${nodeType} node` },
  //                     dragHandle: '.custom-drag-handle',
  //
  //   };
  //
  //   const newNode = {
  //               id: getId(nodeType, nodes),
  //               type,
  //               position,
  //               data: { label: `${type} node` },
  //               dragHandle: '.custom-drag-handle',
  //           };
  //
  //   reactFlowInstance.setNodes((nds) => nds.concat(newNode));
  //
  // };

  return (
      <aside>
        {/*<div className="description">You can drag these nodes to the pane on the right.</div>*/}
        {/*<div className="dndnode input" id={'position'} onDragStart={(event) => onDragStart(event, 'input')} draggable>*/}
        {/*  Input Node*/}
        {/*</div>*/}
        {/*  <div className="dndbarnode btn-outline-secondary mb-1 small"*/}
        {/*     onDragStart={(event) => onDragStart(event, 'divTreeNode')}*/}
        {/*     draggable*/}
        {/*    // onClick={() => onClickAddNode('positionNode')}*/}
        {/*>*/}
        {/*  Drag To Add a New Division*/}
        {/*</div>*/}
        <div className="dndbarnode btn-outline-secondary mb-1 small"
             onDragStart={(event) => onDragStart(event, 'positionNode')}
             draggable
            // onClick={() => onClickAddNode('positionNode')}
        >
          Drag To Add Position Outside of div/dept
        </div>
        <div className="dndbarnode btn-outline-secondary mb-1 small "
             onDragStart={(event) => onDragStart(event, 'headerNode')}
             draggable
            // onClick={() => onClickAddNode('headerNode')}
        >
          Drag To Add Header
        </div>

        <div className="dndbarnode btn-outline-secondary mb-1 small "
             onDragStart={(event) => onDragStart(event, 'textNode')}
             draggable
            // onClick={() => onClickAddNode('textNode')}
        >
          Drag To Add Text
        </div>
        {/*<div className="dndnode output" onDragStart={(event) => onDragStart(event, 'output')} draggable>*/}
        {/*  Output Node*/}
        {/*</div>*/}
      </aside>
  );
};

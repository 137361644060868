import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FiTrash2 } from 'react-icons/fi';

export const KB_ARTICLE_ACKNOWLEDGEMENT_SIGN = 1;
export const KB_ARTICLE_ACKNOWLEDGEMENT_QUESTION = 2;

export default function KBChallengeBuilderSelect({ selected, question, answers, onChange }) {
    const [challengeType, setChallengeType] = useState(selected);
    const [answerItems, setAnswerItems] = useState(answers || []);
    const [questionText, setQuestionText] = useState(question || '');

    const addAnswerOption = () => {
        const newItems = [...answerItems, { answer: '', is_correct: false }];
        setAnswerItems(newItems);
    };

    const removeAnswerOption = (index) => {
        if (answerItems.length > 0) {
            const items = [...answerItems];
            items.splice(index, 1);
            setAnswerItems(items);
        }
    };

    const handleChallengeChange = (e) => {
        const value = e.target.value;
        setChallengeType(value ? parseInt(value) : value);

        if (parseInt(value) === KB_ARTICLE_ACKNOWLEDGEMENT_QUESTION) {
            // if acknowledgment of type Question and answer and no anwers, we initialize with an empty answer array
            answerItems.length === 0 && addAnswerOption();
        }
    };

    const handleAnswerChange = (e, index) => {
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;

        const newItems = [...answerItems];
        const itemBeingUpdated = newItems[index];

        if (itemBeingUpdated) {
            if (name === 'answer') {
                itemBeingUpdated.answer = value;
            }
            if (name === 'is_correct') {
                itemBeingUpdated.is_correct = checked;
            }
        }

        setAnswerItems(newItems);
    };

    useEffect(() => {
        const kbquiz = challengeType == KB_ARTICLE_ACKNOWLEDGEMENT_QUESTION ? questionText : '';
        const kbAnswers = challengeType == KB_ARTICLE_ACKNOWLEDGEMENT_QUESTION ? answerItems : null;
        typeof onChange === 'function' &&
            onChange({ acknowledgement_type: challengeType || null, acknowledgement_question: kbquiz, article_answers: kbAnswers });
    }, [questionText, answerItems, challengeType]);

    const questionAnswerBuilder = (
        <Form.Group controlId="question">
            <Form.Control
                name="acknowledgement_question"
                placeholder={'Enter your question here'}
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
                required={true}
            />

            {answerItems?.map((item, index) => {
                return (
                    <Row className="align-items-center mt-2" key={index}>
                        <Col md={6} sm={6}>
                            <Form.Control
                                name="answer"
                                placeholder={'Enter answer'}
                                value={item.answer}
                                position={index}
                                onChange={(e) => handleAnswerChange(e, index)}
                                required={true}
                            />
                        </Col>
                        <Col md={3} sm={3}>
                            <Form.Check
                                name={'is_correct'}
                                type={'checkbox'}
                                label={'Is Correct'}
                                checked={item.is_correct}
                                position={index}
                                onChange={(e) => handleAnswerChange(e, index)}
                            />
                        </Col>
                        <Col md={3} sm={3}>
                            {answerItems?.length > 1 && (
                                <span className="cursor-pointer" onClick={() => removeAnswerOption(index)}>
                                    <FiTrash2 /> Remove
                                </span>
                            )}
                        </Col>
                    </Row>
                );
            })}

            <Button variant="primary" size="sm" className="mt-2" onClick={addAnswerOption}>
                Add Answer
            </Button>
        </Form.Group>
    );

    return (
        <div>
            <Form.Group controlId="challenge">
                <Form.Label>Create a Challenge for Acknowledgement</Form.Label>
                <Form.Control as="select" value={selected} onChange={handleChallengeChange}>
                    <option value={''}>No Challenge</option>
                    <option value={KB_ARTICLE_ACKNOWLEDGEMENT_SIGN}>SIGNATURE</option>
                    <option value={KB_ARTICLE_ACKNOWLEDGEMENT_QUESTION}>QUESTION AND ANSWER</option>
                </Form.Control>
            </Form.Group>
            {challengeType === KB_ARTICLE_ACKNOWLEDGEMENT_QUESTION && questionAnswerBuilder}
        </div>
    );
}

import { API_SERVER } from '../config/constant';
import http from './httpService';

const getCompanyDivisions = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}division/api/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};
const createCompanyDivisions = async (addData) => {
    try {
        const { data } = await http.post(`${API_SERVER}division/api/`, addData, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const updateCompanyDivisions = async (id, addData) => {
    try {
        const { data } = await http.patch(`${API_SERVER}account/api/add-delete-menu-link/${id}/`, addData, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const deleteCompanyDivisions = async (id) => {
    try {
        await http.delete(`${API_SERVER}division/api/${id}/`, http.tokenConfig());
        return 'success';
    } catch (error) {
        console.log(error);
        return false;
    }
};

const getDivisionsWitEmployees = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}division/api/division-with-employees/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export default {
    getCompanyDivisions,
    createCompanyDivisions,
    updateCompanyDivisions,
    deleteCompanyDivisions,
    getDivisionsWitEmployees
};

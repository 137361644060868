import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import knowledgeService from '../../services/knowledgeService';

export default function GenerateKBAIModal({ setKBBody }) {
    const [AICategories, setAICategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);

    const hideModal = () => setShowModal(false);

    const handleGenerate = () => {
        const body = selectedSubject?.subject_article || '';
        console.log('article',body,selectedSubject,selectedCategory)
        typeof setKBBody === 'function' && setKBBody(body);
        hideModal();
    };

    const fetchAIArticles = async () => {
        setLoading(true);
        const data = await knowledgeService.getAutoAIKBArticles();
        if (data) {
            setAICategories(data);
            setSelectedCategory(data[0]);
            setLoading(false);
        }
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedSubject(null);
    };

    const handleSubjectClick = (subject) => {
        setSelectedSubject(subject);
    };

    useEffect(() => {
        // if (!AICategories || AICategories?.length === 0) {
        fetchAIArticles();
        // }
    }, []);

    return (
        <>
            <Button
                size="sm"
                // className='btn-sm ' style={{marginTop:"-75px",marginLeft:"160px",marginBottom:20, zIndex:5, position:"relative"}}
                onClick={handleShowModal}
            >
                Templates
            </Button>

            <Modal size="lg" show={showModal} onHide={hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Article Templates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <p className="text-center">Loading...</p>
                    ) : (
                        <div className="row">
                            <div className="col-md-4">
                                <h6 className="mb-4">Choose Category</h6>
                                {AICategories?.map((category) => (
                                    <p
                                        key={category.id}
                                        id={category.id}
                                        className={`cursor-pointer ${selectedCategory === category && 'btn btn-primary'}`}
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        {category.name}
                                    </p>
                                ))}
                            </div>
                            <div className="col-md-4 border-left border-secondary">
                                <h6 className="mb-4">Choose Subject</h6>
                                <div id="subject-container">
                                    {selectedCategory?.category_subjects?.map((subject) => (
                                        <p
                                            key={subject?.id}
                                            id={subject?.id}
                                            className={`cursor-pointer  ${selectedSubject === subject && 'btn btn-primary'}`}
                                            onClick={() => handleSubjectClick(subject)}
                                        >
                                            {subject.name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-4 border-left border-secondary">
                                <div className="d-block h-100" style={{ position: 'relative' }}>
                                    <h6 className="mb-4">Details</h6>
                                    <div id="ai-tags-container">
                                        {selectedSubject ? (
                                            selectedSubject?.subject_article ? (
                                                <>
                                                    <p>
                                                        <span className="font-weight-bold">Name: </span>
                                                        {selectedSubject?.name}
                                                    </p>
                                                    <p>
                                                        <span className="font-weight-bold">Description: </span>
                                                        {selectedSubject?.description}
                                                    </p>
                                                </>
                                            ) : (
                                                <p>No article for this subject</p>
                                            )
                                        ) : (
                                            <small className="text-center text-muted">Select subject to see details</small>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hideModal}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={!selectedSubject?.subject_article} onClick={handleGenerate}>
                        Generate
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

// action - state management
import { SET_CLOCK_TAGS } from './actions';

export const initialState = {
    clockTags: []
};

//-----------------------|| INOUT REDUCER ||-----------------------//

const inoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLOCK_TAGS:
            console.log('SET_CLOCK_TAGS dispatched >>>>', action.payload);
            return {
                ...state,
                clockTags: action.payload
            };

        default: {
            return { ...state };
        }
    }
};

export default inoutReducer;

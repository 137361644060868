import React, { useEffect, useState } from 'react';
import { Collapse, Form } from 'react-bootstrap';
import knowledgeService from '../../../services/knowledgeService';

export default function ArticleVisibilityInput({ onChange, visibilityValue, publicKBSValue = [] }) {
    const [publicKBs, setPublicKBs] = useState([]);
    const [loading, setLoading] = useState(false);
    const publicVisibility = 0;

    const visibilityOptions = [
        { id: publicVisibility, name: 'PUBLIC' },
        { id: 1, name: 'ALL STAFF' },
        { id: 2, name: 'ASSIGNED ONLY' }
    ];

    useEffect(() => {
        const fetchPublicKBs = async () => {
            setLoading(true);
            const data = await knowledgeService.getPublicKnowledgebases();
            if (data) {
                setPublicKBs(data);
            }
            setLoading(false);
        };

        fetchPublicKBs();
    }, []);

    const setPublicKBOnChange = (kbsList = []) => {
        console.log('setting the value', kbsList);
        onChange({ target: { name: 'public_knowledgebases', value: kbsList } });
    };

    const handleKBCheckboxChange = (e) => {
        const { value, checked } = e.target;

        let newKBs = [...publicKBSValue];

        if (checked) {
            newKBs.push(parseInt(value));
        } else {
            newKBs = newKBs.filter((kb) => kb != value);
        }

        // update the article fields to include the selected public kbs
        setPublicKBOnChange(newKBs);
    };

    useEffect(() => {
        if (visibilityValue == publicVisibility && publicKBs.length == 1) {
            // if the article is public and only one KB, select the default kb to add that article in there.
            const defaultKB = publicKBs.filter((kb) => kb.is_default)[0];
            setPublicKBOnChange([defaultKB.id]);
        }
        if (parseInt(visibilityValue) !== publicVisibility) {
            setPublicKBOnChange([]);
        }
    }, [visibilityValue, publicKBs.length]);

    return (
        <Form.Group controlId="visibility">
            <Form.Label>Visibility</Form.Label>
            <Form.Control as="select" name="visibility" value={visibilityValue} onChange={onChange}>
                {visibilityOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </Form.Control>

            <Collapse in={visibilityValue == publicVisibility}>
                <div className="py-1 px-3 border">
                    {loading ? (
                        <p className="mt-2 text-center">Loading items...</p>
                    ) : publicKBs.length == 1 ? (
                        <Form.Text>If you want multiple knowledgebases go to settings</Form.Text>
                    ) : (
                        <>
                            <Form.Text>Please select the Public KnowledgeBase the article belongs to:</Form.Text>
                            {publicKBs.map((kb) => (
                                <Form.Check
                                    key={kb.id}
                                    type="checkbox"
                                    label={kb.name}
                                    value={kb.id}
                                    checked={publicKBSValue.includes(kb.id)}
                                    onChange={handleKBCheckboxChange}
                                />
                            ))}
                        </>
                    )}
                </div>
            </Collapse>
        </Form.Group>
    );
}

import React, { useEffect, useState } from 'react';
import ReactTags from 'react-tag-autocomplete';

export default function TagsInput({ tagsList, onChange }) {
    const [tags, setTags] = useState([]);

    const handleDelete = (i, states) => {
        const newTags = tags.slice(0);
        newTags.splice(i, 1);
        setTags(newTags);
    };

    const handleAddition = (tag, state) => {
        if (tag.name !== '') {
            const newTags = [].concat(tags, tag);
            setTags(newTags);
        }
    };

    useEffect(() => {
        typeof onChange === 'function' && onChange(tags);
    }, [tags]);

    useEffect(() => {
        tagsList && setTags(tagsList?.map((tag) => ({ id: tag, name: tag })));
    }, []);

    return (
        <ReactTags
            ref={React.createRef()}
            allowNew={true}
            minQueryLength={0}
            delimiters={['Enter', 'Tab', ',']}
            addOnBlur={true}
            onDelete={handleDelete}
            onAddition={handleAddition}
            tags={tags}
            placeholderText={'Select Tags'}
            style={{ zIndex: 200 }}
        />
    );
}

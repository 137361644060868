import {
    GET_MY_REQUESTS,
    GET_REQUESTS,
    GET_REQUESTS_FOR_APPROVAL,
    GET_REQUEST_DETAILS,
    GET_TASK_DETAILS,
    LOADING_REQUEST_DETAILS,
    REQUEST_DETAILS_ERROR
} from './actions';

export const initialState = {
    myRequests: [],
    forApproval: [],
    requests: [],
    request: {},
    task: {},
    loadingRequestDetails: false,
    requestDetailsError: false
};

const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_REQUESTS:
            return {
                ...state,
                myRequests: action.payload
            };

        case GET_REQUESTS_FOR_APPROVAL:
            return {
                ...state,
                forApproval: action.payload
            };

        case GET_REQUESTS:
            return {
                ...state,
                requests: action.payload
            };

        case GET_REQUEST_DETAILS:
            return {
                ...state,
                request: action.payload
            };

        case GET_TASK_DETAILS:
            return {
                ...state,
                task: action.payload
            };

        case LOADING_REQUEST_DETAILS:
            return {
                ...state,
                loadingRequestDetails: action.payload
            };
        case REQUEST_DETAILS_ERROR:
            return {
                ...state,
                requestDetailsError: action.payload
            };

        default:
            return state;
    }
};

export default requestReducer;

import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import knowledgeService from '../../../services/knowledgeService';
import {Knowledge} from "../../../services";

export default function ArticleTypeForm({ instance, handleCloseModal, callbackFunc }) {
    const Schema = Yup.object().shape({
        type: Yup.string().required('This field is required'),
        color: Yup.string().required('This field is required')
    });

    const handleSubmit = async (formData, actions) => {
        let data;
        if (instance) {
            // means we are updating an article type instance
            data = await knowledgeService.updateArticleType(instance.id, formData);
        } else {
            // we are creating an article type
            data = await knowledgeService.createArticleType(formData);
        }

        if (data) {
            typeof callbackFunc === 'function' && callbackFunc(data);
        }

        actions.setSubmitting(false);
    };

    const handleDeleteCategory = async (e) => {
        e.preventDefault();
        // Call the API to delete the selected category
        const data = await Knowledge.deleteArticleType(instance.id);
        // Refresh the categories

            typeof callbackFunc === 'function' && callbackFunc(data);
            handleCloseModal()

    };

    return (
        <Formik
            validationSchema={Schema}
            // validate={validate}
            initialValues={{
                type: instance?.type,
                color: instance?.color,
                description: instance?.description,
                is_default: instance?.is_default
            }}
            onSubmit={handleSubmit}
        >
            {({ errors, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <label htmlFor="type">Type</label>
                        <Field className="form-control" name="type" type="text" placeholder="Enter type name..." />
                        {errors.type && <span className="text-danger">{errors.type}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="color">Color</label>
                        <Field className="color-input" name="color" type="color" placeholder="Enter color..." />
                        {errors.color && <span className="text-danger">{errors.color}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="color">Description</label>
                        <Field className="form-control" name="description" component="textarea" placeholder="Enter description..." />
                        {errors.description && <span className="text-danger">{errors.description}</span>}
                    </div>
                    <div className="form-check">
                        <Field className="form-check-input" name="is_default" type="checkbox" />
                        <label className="form-check-label" htmlFor="is_default">
                            Is Default
                        </label>
                        <small className="form-text text-muted">You can only have one default article type</small>

                        {errors.is_default && <span className="text-danger">{errors.is_default}</span>}
                    </div>
                    {instance &&
                        <Button className='btn-danger mr-auto' onClick={handleDeleteCategory}>
                            Delete
                        </Button>
                    }
                    <Button type="submit" className="mt-3 float-right" disabled={isSubmitting}>
                        {isSubmitting ? 'Saving....' : 'Save'}
                    </Button>
                    <Button variant='Secondary' className="mt-3 float-right" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

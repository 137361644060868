import React, { useEffect, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import knowledgeService from '../../../services/knowledgeService';
import { formatToReactSelectOption } from '../../../utils/common';
import ArticleModal from '../../KnowledgeBase/ArticleList/ArticleModal';

export default function ArticlesMulltiSelectInput({ value, defaultValue, articleChoices, handleInputChange }) {
    const [articles, setArticles] = useState(articleChoices ? articleChoices : []);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(value);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const fetchArticles = async () => {
        if (articles.length === 0) {
            setLoading(true);
            const data = await knowledgeService.getKBArticles({ simple_details: true, paginate: false });

            if (data) {
                const formattedData = formatToReactSelectOption(data, 'title');
                setArticles(formattedData);
                setLoading(false);
            }
        }
    };

    const handleChange = (data, options) => {
        if (typeof handleInputChange === 'function') {
            handleInputChange(data, options);
        }
        setSelected(data);
    };

    const updateArticles = (data) => {
        // updates the articles with the data passed
        setArticles([data, ...articles]);
    };

    const addSelectedArticle = (item) => {
        // selects the item (article) passed to the input
        const data = [...selected, { id: item.id, value: item.id, label: item.title }];
        handleChange(data, { name: 'articles' });
    };

    const articleAddCallbackFunc = (data) => {
        updateArticles(data);
        addSelectedArticle(data);
        handleCloseModal();
    };

    useEffect(() => {
        if (value) {
            setSelected(value);
        }
    }, [value]);

    useEffect(() => {
        fetchArticles();
    }, []);

    return (
        <>
            <Form.Group>
                <Form.Label>Articles</Form.Label>
                <div className="d-flex">
                    <Select
                        className="basic-single w-100"
                        classNamePrefix="select"
                        defaultValue={defaultValue}
                        value={selected}
                        isDisabled={loading}
                        isLoading={loading}
                        placeholder={loading ? 'loading...' : 'Select Articles'}
                        isClearable={true}
                        isMulti
                        isSearchable={true}
                        name="articles"
                        onChange={handleChange}
                        options={articles}
                    />
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Create New Article</Tooltip>}>
                        <button className="btn btn-sm btn-icon mx-0" type="button" style={{ height: 42.8 }} onClick={handleShowModal}>
                            {' '}
                            <i className="feather icon-plus fa-lg mx-0"> </i>
                        </button>
                    </OverlayTrigger>
                </div>
            </Form.Group>
            <ArticleModal showCreate={showModal} setShowCreate={setShowModal} callbackFunc={articleAddCallbackFunc} />
        </>
    );
}

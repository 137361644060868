import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import subscriptionService from '../services/subscriptionService';
import { setSubscription } from '../store/accountActions';

export default function useGetSubscription() {
    // Hook to get user subscription info
    const mySubscription = useSelector((state) => state.account?.subscription);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const getMySubscription = async () => {
        setLoading(true);
        const data = await subscriptionService.getMySubscriptionDetails();
        let mySubscription = {};
        if (data) {
            mySubscription = data;
        }
        dispatch(setSubscription(mySubscription));
        setLoading(false);
    };

    useEffect(() => {
        getMySubscription();
    }, []);

    return { mySubscription, loading, getMySubscription };
}

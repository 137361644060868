import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SET_REQUESTED_URL } from '../../store/actions';

const AuthGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();
    const { isLoggedIn } = account;

    // we get the current requested url to redirect
    // the user to it after login
    const requestedUrl = window.location.pathname;

    useEffect(() => {
        // set the requested url to state
        dispatcher({
            type: SET_REQUESTED_URL,
            payload: requestedUrl
        });
    }, []);

    if (!isLoggedIn) {
        return <Redirect to="/auth/signin" />;
    }

    return children;
};

export default AuthGuard;

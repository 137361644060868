import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import NavLeft from './NavLeft';
import NavRight from './NavRight';

import { ConfigContext } from '../../../contexts/ConfigContext';
import * as actionType from '../../../store/actions';
import {useSelector} from "../../../store";

const NavBar = () => {
    //const [moreToggle, setMoreToggle] = useState(false);
    const configContext = useContext(ConfigContext);
    const { collapseMenu, headerBackColor, headerFixedLayout, layout, subLayout } = configContext.state;
    const { dispatch } = configContext;
    const user = useSelector((state) => state.account.user);

    let headerClass = ['navbar', 'pcoded-header', 'navbar-expand-lg', headerBackColor];
    if (headerFixedLayout && layout === 'vertical') {
        headerClass = [...headerClass, 'headerpos-fixed'];
    }

    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    const navToggleHandler = () => {
        dispatch({ type: actionType.COLLAPSE_MENU });
    };

    // let moreClass = ['mob-toggler'];;
    // if (layout === 'horizontal') {
    //     moreClass = [...moreClass, 'd-none'];
    // }
    let collapseClass = ['collapse navbar-collapse'];
    // if (moreToggle) {
    //     //moreClass = [...moreClass, 'on'];
    //     collapseClass = [...collapseClass, 'd-block']
    // }


    let navBar = (
        <React.Fragment>
            <div className="m-header">
                <Link to="#" className={toggleClass.join(' ')} id="mobile-collapse" onClick={navToggleHandler}>
                    <span />
                </Link>
                <Link to="/account/dashboard" className="b-brand">
                    {/*Company name and logo*/}
                    {user.company?.company_logo?
                        <div>
                        <div className="force-center mt-2  force-vertical-center">
                            <div className="">
                            <img className="img-fluid force-center " data-wg-notranslate="manual"
                                 style={{maxWidth:179, maxHeight: 70, objectFit: 'contain', }}
                                 src={user.company?.company_logo} alt="company-logo"/>
                                <span className="b-title ">
                                <span
                                    className="powered-by pt-2">
                                    Powered by {process.env.REACT_APP_NAME}
                                </span>
                            </span>
                            </div>
                            <br/>
                        </div>
                        </div>
                        :
                        <>
                            <div className="b-bg">
                                <i className="feather icon-trending-up"/>
                            </div>
                            <span className="b-title force-center">
                                {process.env.REACT_APP_NAME}
                                <h6 className="text-light font-weight-light">
                            <small id="company_name_nav">{user.company?.company_name}</small>
                        </h6>
                    </span></>}
                </Link>
            </div>
            <div className={collapseClass.join(' ')}>
                <NavLeft />
                <NavRight />
            </div>
        </React.Fragment>
    );

    if (layout === 'horizontal' && subLayout === 'horizontal-2') {
        navBar = <div className="container">{navBar}</div>;
    }

    return (
        <React.Fragment>
            <header className={headerClass.join(' ')}>{navBar}</header>
        </React.Fragment>
    );
};

export default NavBar;

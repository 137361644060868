import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import knowledgeService from '../../../services/knowledgeService';
import { sortListOfObjectsAlphabetically } from '../../../utils/common';
import AddArticleTypeModal from '../AddArticleTypeModal';
import EditArticleTypeModal from '../EditArticleTypeModal';

export default function ArticleTypeInput({ selected, onChange, canAdd, canEdit }) {
    const [articleTypes, setArticleTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    const setSelected = (typeId) => {
        const event = { target: { value: typeId, name: 'type' } };
        onChange(event);
    };

    const fetchArticleTypes = async () => {
        setLoading(true);
        const data = await knowledgeService.getArticleTypes();
        if (data) {
            setArticleTypes(data);

            if (!selected) {
                // if not selected, set the selected value as the default
                // article type
                const defaultType = data.find((item) => item.is_default === true);
                defaultType && setSelected(defaultType.id);
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchArticleTypes();
    }, []);

    const handleAddTypeCallback = (data) => {
        const newTypes = [...articleTypes, data];
        const sortedTypes = sortListOfObjectsAlphabetically(newTypes, 'type');
        setArticleTypes(sortedTypes);

        // set the selected option in the input
        setSelected(data.id);
    };

    const handleUpdateTypeCallback = (data) => {
        // To be implemented for edit
        const articleTypesCopy = [...articleTypes];
        const updatedTypeIndex = articleTypesCopy.findIndex((type) => type.id === data.id);
        articleTypesCopy[updatedTypeIndex] = data;
        setArticleTypes(articleTypesCopy);
    };

    return (
        <Form.Group controlId="type">
            <Form.Label>Type</Form.Label>
            <div className="d-flex">
                <Form.Control as="select" className="mr-1" name="type" value={selected} onChange={onChange}>
                    {loading ? (
                        <option selected disabled>
                            Loading...
                        </option>
                    ) : (
                        articleTypes?.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.type}
                            </option>
                        ))
                    )}
                </Form.Control>
                {canAdd && <AddArticleTypeModal callbackFunc={handleAddTypeCallback} />}
                {canEdit && selected && (
                    <EditArticleTypeModal
                        instance={articleTypes.find((item) => item.id == selected)}
                        callbackFunc={handleUpdateTypeCallback}
                    />
                )}
            </div>
        </Form.Group>
    );
}

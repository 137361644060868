import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';

import { updateLanguage } from '../../../../store/action_calls';

const LightDark = (props) => {

    const configContext = useContext(ConfigContext);
        const { layoutType } = configContext.state;
    const { dispatch } = configContext;

    const user = useSelector((state) => state.account.user);

    useEffect(() => {
        // Check if user has a preference in the database
        if (user?.staff_info?.is_dark_mode !== undefined) {
            updateTheme(user.staff_info.is_dark_mode);
        } else {
            // If no preference, check the system preference
            const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            updateTheme(prefersDarkMode);
        }
    }, [user?.staff_info]);

    const updateTheme = (isDarkMode) => {
        if (isDarkMode) {
            dispatch({
                type: actionType.LAYOUT_TYPE,
                layoutType: 'dark',
                headerBackColor: 'header-dark',
                navBackColor: 'navbar-dark',
                navBrandColor: 'brand-dark'
            });
        } else {
            dispatch({
                type: actionType.LAYOUT_TYPE,
                layoutType: 'menu-dark',
                headerBackColor: 'header-default',
                navBackColor: 'navbar-default',
                navBrandColor: 'brand-default'
            });
        }
    };

    const handleEdit = (value) => {
        props.updateLanguage(user?.id, { is_dark_mode: value });
        updateTheme(value);
    };

    return (
        <React.Fragment>
            <div>
                <Link
                    to="#"
                    onClick={() => {
                        dispatch({
                            type: actionType.LAYOUT_TYPE,
                            layoutType: 'menu-dark',
                            headerBackColor: 'header-default',
                            navBackColor: 'navbar-default',
                            navBrandColor: 'brand-default'
                        });
                        handleEdit(false);
                    }}
                    title="Light theme"
                    className={layoutType === 'menu-dark' ? 'text-primary feather icon-sun fa-lg mr-1' : ' feather icon-sun  fa-lg mr-1'}
                    data-value="menu-dark"
                >
                    <span />
                    <span />
                </Link>
                <Link
                    to="#"
                    onClick={() => {
                        dispatch({
                            type: actionType.LAYOUT_TYPE,
                            layoutType: 'dark',
                            headerBackColor: 'header-dark',
                            navBackColor: 'navbar-dark',
                            navBrandColor: 'brand-dark'
                        });
                        handleEdit(true);
                    }}
                    title="Dark Theme"
                    className={layoutType === 'dark' ? 'text-success feather icon-moon fa-lg mr-1' : 'feather icon-moon fa-lg mr-1'}
                    data-value="dark"
                >
                    <span />
                    <span />
                </Link>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    forApproval: state.requestReducer.forApproval
});

export default connect(mapStateToProps, { updateLanguage })(LightDark);

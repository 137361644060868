import axios from 'axios';
import { toast } from 'react-toastify';

// Import API Server
import debounce from 'lodash.debounce';
import { API_SERVER } from '../config/constant';
import { LOGOUT } from '../store/actions';
import { store } from '../store/index';

export const tokenConfig = () => {
    const token = store.getState().account.token;
    // const token = 'store.getState().account.token';

    const config = {
        headers: {
            'Content-Type': 'application/json'
            // 'Cache-Control': 'no-cache',
            // 'Pragma': 'no-cache',
            // 'Expires': '0',
        }
    };

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
};

const tokenConfigFile = () => {
    const token = store.getState().account.token;

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
};

// Define the debounced toast function outside of the error handling scope
const debouncedWarningToast = debounce(
    () => {
        toast.warning('Session timed out. Please log in to proceed.');
    },
    300,
    {
        // Wait 2 seconds before showing the toast again
        leading: true,
        trailing: false
    }
);

export const multiPartTokenConfig = () => {
    const token = store.getState().account.token;

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
};

const axiosInstance = axios.create({
    baseURL: API_SERVER,
    timeout: 30000
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (err) => {
        const originalRequest = err.config;

        if (err.response?.status === 401) {
            // clear user in state and redirect to login page
            store.dispatch({ type: LOGOUT });

            debouncedWarningToast();

            // setTimeout(() => {
            //     window.location.href = loginPageRoute;
            // }, 2000);
        }

        return Promise.reject(err);
    }
);

export const serializeQuery = (query) => {
    // used to convert the query dict(object) to url query parameters
    // e.g. input: {full_data: false} => full_data=false
    return Object.keys(query)
        ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
        .join('&');
};

const processErrorMessages = (data, parentKey = '', isNestedArray = false) => {
  if (Array.isArray(data)) {
    data.forEach((item, index) => {
        console.log("item"+index, item)
      // Construct a new key for array items. Include the index only if it's a nested array.
      const newKey = isNestedArray ? `${parentKey}[${index}]` : parentKey;
      // When processing items of an array, mark it as nested for the next level of recursion.
      processErrorMessages(item, newKey, true);
    });
  } else if (typeof data === 'object') {
    for (const [key, value] of Object.entries(data)) {
      // Construct a new key that includes the parent key if it exists.
      // Reset isNestedArray to false because we're now dealing with object properties, not array items.
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      processErrorMessages(value, newKey, false);
    }
  } else {
    // Assuming `data` is a string at this point.
    // Display the error message with the fully constructed key that includes indices for nested array elements.
    toast.error(`${parentKey}: ${data}`);
  }
};

export const showAxiosErrorNotification = (errorResponse) => {
  if (errorResponse?.data && errorResponse?.status === 400) {
    processErrorMessages(errorResponse.data);
  } else if (errorResponse?.status === 500) {
    toast.error('A server error occurred, Please try again or contact support to have it fixed immediately');
  } else {
    toast.error('An error occurred');
  }
};

const getSource = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return source;
};

export default {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    patch: axiosInstance.patch,
    delete: axiosInstance.delete,
    options: axiosInstance.options,
    getSource,
    tokenConfig,
    tokenConfigFile,
    multiPartTokenConfig
};

import React, { useContext } from 'react';
import { Dropdown, ListGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import userService from '../../../../services/userService';
import { LOGOUT } from '../../../../store/actions';

import { FaAngleDown } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../../../assets/images/user/avatar-2.jpg';
import avatar4 from '../../../../assets/images/user/avatar-4.jpg';
import { billingPageRoute, settingsPageRoute } from '../../../../routes';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <i className="feather icon-settings pr-1 f-18"/>
        {/*<FiSettings className="pr-1" size={24}/>*/}
        <FaAngleDown />
    </a>
));

export default function DropdownMenu() {
    const user = useSelector((state) => state.account.user);
        const isTotalAdmin = user?.user_type?.user_type_slug === 'admin'

    const configContext = useContext(ConfigContext);
    const dispatcher = useDispatch();
    const { rtlLayout } = configContext.state;

    const handleLogout = () => {
        userService.logoutUser();
        dispatcher({ type: LOGOUT });
    };

    console.log('user :::', user);

    let image = null;

    if (user?.gender === 'FEMALE') {
        image = avatar1;
    } else if (user?.gender === 'OTHER') {
        image = avatar2;
    } else {
        image = avatar4;
    }

    return (
        <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle as={CustomToggle} variant="link" to="#" id="dropdown-basic"></Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
                <div className="pro-head">
                    <img
                        src={user?.photo || image}
                        class="img-radius img-fluid"
                        style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                        alt="User Profile Image"
                    />

                    {user?.first_name && user?.last_name ? (
                        <span>
                            {user?.first_name} {user?.last_name}
                        </span>
                    ) : (
                        <span>{user?.email}</span>
                    )}
                </div>
                <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body">
                    <ListGroup.Item as="li" bsPrefix=" ">
                        <Link to={settingsPageRoute} className="dropdown-item">
                            <i className="feather icon-settings" /> Settings
                        </Link>
                    </ListGroup.Item>
                    <li><a href="#" className="dropdown-item" id="accessibilityWidget" tabindex="0">
                        <i className="fab fa-accessible-icon" id="accessibilityWidget"/> Accessibility Options
                    </a></li>
                    {isTotalAdmin&&<ListGroup.Item as="li" bsPrefix=" ">
                        <Link to={billingPageRoute} className="dropdown-item">
                            <i className="feather icon-shield" /> Subscription
                        </Link>
                    </ListGroup.Item>}

                    <ListGroup.Item as="li" bsPrefix=" ">
                        <Link to="#" className="dropdown-item" onClick={handleLogout}>
                            <i className="feather icon-log-out" /> Logout
                        </Link>
                    </ListGroup.Item>
                </ListGroup>
            </Dropdown.Menu>
        </Dropdown>
    );
}

import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function FormSkeletonLoader() {
    return (
        <Row>
            <Col md={6} sm={12}>
                <Skeleton variant="rect" height={15} width={'50%'} className="mt-2 rounded" animation={false} />
                <Skeleton variant="rect" height={45} className="mt-2 mb-3 rounded" animation={'wave'} />
            </Col>
            <Col md={6} sm={12}>
                <Skeleton variant="rect" height={15} width={'50%'} className="mt-2 rounded" animation={false} />
                <Skeleton variant="rect" height={45} className="mt-2 mb-3 rounded" animation={'wave'} />
            </Col>
            <Col md={6} sm={12}>
                <Skeleton variant="rect" height={15} width={'50%'} className="mt-2 rounded" animation={false} />
                <Skeleton variant="rect" height={45} className="mt-2 mb-3 rounded" animation={'wave'} />
            </Col>
            <Col md={6} sm={12}>
                <Skeleton variant="rect" height={15} width={'50%'} className="mt-2 rounded" animation={false} />
                <Skeleton variant="rect" height={45} className="mt-2 mb-3 rounded" animation={'wave'} />
            </Col>
            <Col md={12} sm={12}>
                <Skeleton variant="rect" height={15} width={'30%'} className="mt-2 rounded" animation={false} />
                <Skeleton variant="rect" height={45} className="mt-2 mb-3 rounded" animation={'pulse'} />
            </Col>
            <Col md={12} sm={12}>
                <Skeleton variant="rect" height={350} animation={'wave'} />
            </Col>
            <Col md={12} sm={12}>
                <Skeleton variant="rect" height={45} width="20%" className="float-right mt-3 rounded" animation={false} />
            </Col>
        </Row>
    );
}

import {
    ADD_COURSE_SECTION_ARTICLE,
    CLEAR_KB_STATE,
    SET_COURSE_CATEGORIES,
    SET_COURSE_DETAIL,
    SET_COURSE_DETAIL_ARTICLES,
    SET_COURSE_DETAIL_ITEMS,
    SET_COURSE_DIVISIONS,
    SET_COURSE_EDIT_DATA,
    SET_COURSE_FORM_ERRORS,
    SET_COURSE_INDIVIDUALS,
    SET_COURSE_POSITIONS,
    SET_COURSE_PROGRESS,
    SET_COURSE_RESPONSIBILITIES,
    SET_COURSE_SECTIONS,
    SET_ACTIVE_ARTICLE,
    SET_COURSE_SECTION_ITEM_BEING_READ_ID,
    SET_DISABLE_COURSE_ITEM_DRAG,
    SET_FORM_DATA,
    SET_HAS_COMPLETED_ALL_COURSE_ITEMS,
    SET_QUIZZES,
    TRIGGER_RE_FETCH_ARTICLE_DETAIL_ACKNOWLEDGEMENTS,
    TRIGGER_RE_FETCH_QUESTIONS,
    UPDATE_COURSE_SECTION_ARTICLES,
    UPDATE_SECTION_TITLE
} from './actions';

export const initialState = {
    courseSections: [
        {
            id: 1,
            order: 1,
            title: '',
            section_items: []
        }
    ],
    courseCategories: [],
    courseName: '',
    courseCategory: '',
    courseDescription: '',
    coursePriority: 0,
    courseDivisions: [],
    courseIndividuals: [],
    coursePositions: [],
    courseResponsibilities: [],
    courseIsAssignedToEveryone: false,
    courseReassignDuration: 0,
    courseFormErrors: {
        courseName: '',
        courseCategory: '',
        coursePriority: '',
        courseSections: ''
    },
    courseDetailArticles: [],
    courseProgress: 0,
    courseDetail: '',
    courseItemBeingReadId: '',
    hasCompletedAllCourseItems: false,
    refetchQuestions: false,
    refetchArticleDetailAcknowledgements: false,
    disableCourseItemDrag: false,
    quizzes: []
};

const knowledgebaseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COURSE_SECTIONS:
            return {
                ...state,
                courseSections: [...action.payload]
            };

            case SET_ACTIVE_ARTICLE:
                return {
                    ...state,
                    articleId: [...action.payload]
                };

        case ADD_COURSE_SECTION_ARTICLE:
            const sectionId = action.sectionId;
            const newItem = action.payload;

            // create a copy of the current course sections to avoid mutating the state
            let newSections = [...state.courseSections];

            // Get the section that is being updated (items being added to it)
            const selectedSection = newSections.find((_section) => _section.id === sectionId);

            if (selectedSection) {
                // Create a new item which has order to it
                const newItemWithOrder = { ...newItem, order: selectedSection.section_items.length + 1 };

                // Create a copy of the section with the new item with order appended to the section items.
                const updatedSection = {
                    ...selectedSection,
                    section_items: [...selectedSection.section_items, newItemWithOrder]
                };

                // Replace the current section with the new updatedSection which has the new item appended to it
                // at the same index.
                const i = newSections.findIndex((_section) => _section.id === sectionId);
                if (i > -1) newSections[i] = updatedSection;
            }

            return {
                ...state,
                courseSections: newSections
            };

        case UPDATE_COURSE_SECTION_ARTICLES:
            const selectedSectionId = action.sectionId;
            const newSectionArticles = action.payload;

            console.log('new section articles ', newSectionArticles);

            const updatedSections = state.courseSections.map((section) => {
                if (section.id === selectedSectionId) {
                    return {
                        ...section,
                        section_items: newSectionArticles
                    };
                }
                return section;
            });

            console.log('updated sections ', updatedSections);

            return {
                ...state,
                courseSections: updatedSections
            };

        case SET_FORM_DATA:
            const objectName = action.name;
            const value = action.payload;

            return {
                ...state,
                [objectName]: value
            };

        case UPDATE_SECTION_TITLE:
            let sections = [...state.courseSections];
            const updatingSectionId = action.sectionId;
            const payload = action.payload;

            const updatingSection = sections.find((section) => section.id === updatingSectionId);
            if (updatingSection) {
                let updatedSection = { ...updatingSection };
                updatedSection.title = payload;

                const i = sections.findIndex((_section) => _section.id === updatingSectionId);
                if (i > -1) sections[i] = updatedSection;
            }

            return {
                ...state,
                courseSections: sections
            };

        case SET_COURSE_CATEGORIES:
            return {
                ...state,
                courseCategories: [...action.payload]
            };

        case SET_COURSE_DIVISIONS:
            return {
                ...state,
                courseDivisions: action.payload
            };

        case SET_COURSE_INDIVIDUALS:
            return {
                ...state,
                courseIndividuals: action.payload
            };
        case SET_COURSE_POSITIONS:
            return {
                ...state,
                coursePositions: action.payload
            };
        case SET_COURSE_RESPONSIBILITIES:
            return {
                ...state,
                courseResponsibilities: action.payload
            };
        case SET_COURSE_FORM_ERRORS:
            return {
                ...state,
                courseFormErrors: action.payload
            };
        case SET_QUIZZES:
            console.log('payload iss ssss ', action.payload);
            return {
                ...state,
                quizzes: action.payload
            };
        case CLEAR_KB_STATE:
            return {
                ...state,
                courseSections: [
                    {
                        id: 1,
                        order: 1,
                        title: '',
                        section_items: []
                    }
                ],

                courseName: '',
                courseCategory: '',
                courseDescription: '',
                coursePriority: '0',
                courseDivisions: [],
                courseIndividuals: [],
                coursePositions: [],
                courseResponsibilities: [],
                courseIsAssignedToEveryone: false,
                courseReassignDuration: 0,
                courseFormErrors: {
                    courseName: '',
                    courseCategory: '',
                    coursePriority: '',
                    courseDescription: '',
                    courseSections: ''
                },
                courseDetailArticles: [],
                courseDetailItems: [],
                courseItemBeingReadId: '',
                courseDetail: '',
                hasCompletedAllCourseItems: false,
                disableCourseItemDrag: false
            };
        case SET_COURSE_EDIT_DATA:
            return {
                ...state,
                ...action.payload
            };

        case SET_COURSE_DETAIL_ARTICLES:
            return {
                ...state,
                courseDetailArticles: action.payload
            };

        case SET_COURSE_DETAIL_ITEMS:
            return {
                ...state,
                courseDetailItems: action.payload
            };

        case SET_COURSE_SECTION_ITEM_BEING_READ_ID:
            return {
                ...state,
                courseItemBeingReadId: action.payload
            };

        case SET_HAS_COMPLETED_ALL_COURSE_ITEMS:
            return {
                ...state,
                hasCompletedAllCourseItems: action.payload
            };
        case SET_COURSE_DETAIL:
            return {
                ...state,
                courseDetail: action.payload
            };
        case SET_COURSE_PROGRESS:
            return {
                ...state,
                courseProgress: action.payload
            };
        case TRIGGER_RE_FETCH_QUESTIONS:
            return {
                ...state,
                refetchQuestions: !state.refetchQuestions
            };
        case TRIGGER_RE_FETCH_ARTICLE_DETAIL_ACKNOWLEDGEMENTS:
            return {
                ...state,
                refetchArticleDetailAcknowledgements: !state.refetchQuestions
            };

        case SET_DISABLE_COURSE_ITEM_DRAG:
            return {
                ...state,
                disableCourseItemDrag: action.payload
            };
        default:
            return state;
    }
};

export default knowledgebaseReducer;

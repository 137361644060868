import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import profileReducer from './profileReducer';
import companyReducer from './companyReducer';
import requestReducer from './requestReducer';
import purchaseReducer from './purchaseReducer';
import statsReducer from './statsReducer';
import knowledgebaseReducer from './knowledgebaseReducer';
import inoutReducer from './inoutReducer';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
    account: persistReducer(
        {
            key: 'account',
            storage,
            keyPrefix: 'datta-'
        },
        accountReducer
    ),
    profileReducer,
    companyReducer,
    requestReducer,
    purchaseReducer,
    statsReducer,
    inoutReducer,
    knowledgebaseReducer,
    form: formReducer
});

export default reducers;

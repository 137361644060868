import http from './httpService'

import { API_SERVER } from '../config/constant';
import {toast} from "react-toastify";
import {useSelector} from "react-redux";

const createCheckList=async(obj)=>{
   try{
    await http.post(`${API_SERVER}checklist/`,obj,http.tokenConfig())
  }catch(err){
    console.log(err)
     toast.error('Error creating checklist ' + JSON.stringify(err.response.data))
    return {}
  }
}

const getCheckList=async(id)=>{
  try{
    const {data}=await http.get(`${API_SERVER}checklist/details/${id}/`,http.tokenConfig())
    return data
  }catch(err){
    console.log(err)
    return {}
  }
}

const updateCheckList=async(id,body)=>{
  try {
    const {data}=await http.put(`${API_SERVER}checklist/details/${id}/`,body,http.tokenConfig())
    toast.success(`Checklist updated successfully`)
    return false
  } catch (err) {

    if (err.response.status === 409) { // check if response status is 409 (Conflict)
      toast.warning('Someone updated this Checklist after you opened it, T' +
          'he data is being refreshed so you have the current version. You may have to re-input data')
      return err.response.data.current_instance
    } else {
      toast.error('Error updating checklist ' + JSON.stringify(err.response.data))
      console.log(err)
      return false
    }
  }
}

const uploadFile=async(file)=>{
  // console.log(file)

  const formData = new FormData();
  formData.append('file', file);

  try{
    // todo need to make the company id not something which must be sent from here, the be should grad it and send it.  currently I'm just writing 5 which is wrong.
    const {data}=await http.post(`${API_SERVER}knowledgebase/upload_file/jodit/1/`,formData,http.tokenConfig())
    toast.success(`Checklist file uploaded successfully`)
    return data
  }catch(err){
    toast.error('Error uploading file ' + JSON.stringify(err.response.data))
    console.log(err)
    return {}
  }
}

const patchCheckList=async(id,body)=>{
  console.log('updateCheckList',id,body)
  try{
    const {data}=await http.patch(`${API_SERVER}checklist/details/${id}/`,body,http.tokenConfig())
    toast.success('Checklist updated successfully')
    return data
  }catch(err){
    console.log(err)
    toast.error('Error updating checklist ' + JSON.stringify(err.response.data))
    return {}
  }
}


const getCheckLists=async(query)=>{
  try{
    const {data}=await http.get(`${API_SERVER}checklist/${query?query:''}`,http.tokenConfig())
    return data
  }catch(err){
    console.log(err)
    return {}
  }
}

const getCheckListTemplate=async()=>{
  try{
    const {data}=await http.get(`${API_SERVER}checklist/template/`,http.tokenConfig())
    return data
  }catch(err){
    console.log(err)
    return {}
  }
}


const deleteCheckList=async(id)=>{
  try{
    const {data}=await http.delete(`${API_SERVER}checklist/details/${id}/`,http.tokenConfig())
    toast.warning('Checklist deleted successfully')
    return data
  }catch(err){
    toast.error('Error deleting Checklist ' + JSON.stringify(err.response.data))
    console.log(err)
    return {}
  }
}


// Template Endpoint

const createTemplate=async(obj)=>{
  try{
    await http.post(`${API_SERVER}checklist/template/`,obj,http.tokenConfig())
    toast.success('Checklist created successfully')
    return true
  }catch(err){
    console.log(err)
    toast.error('Error Creating Template ' + JSON.stringify(err.response.data))
    return false
  }
}

const getTemplate=async(id)=>{
  try{
    const {data}=await http.get(`${API_SERVER}checklist/template/details/${id}/`,http.tokenConfig())
    return data
  }catch(err){
    console.log(err)
    return {}
  }
}

const updateTemplate=async(id,body)=>{
  try{
    const {data}=await http.put(`${API_SERVER}checklist/template/details/${id}/`,body,http.tokenConfig())
    toast.success('Template updated successfully')
    return data
  }catch(err){
    toast.error('Error Updating Template ' + JSON.stringify(err.response.data))
    console.log(err)
    return {}
  }
}

const getTemplates=async(query)=>{
  try{
    const {data}=await http.get(`${API_SERVER}checklist/template/${query?"?title="+query:''}`,http.tokenConfig())
    return data
  }catch(err){
    console.log(err)
    return {}
  }
}




const deleteTemplate=async(id)=>{
  try{
    const {data}=await http.delete(`${API_SERVER}checklist/template/details/${id}/`,http.tokenConfig())
    toast.warning('Template deleted successfully')
    return data
  }catch(err){
    toast.error('Error deleting Template ' + JSON.stringify(err.response.data))
    console.log(err)
    return {}
  }
}



export default {
  getTemplate,
  getTemplates,
  deleteTemplate,
  updateTemplate,
  createTemplate,
  createCheckList,
  getCheckList,
  updateCheckList,
  getCheckListTemplate,
  deleteCheckList,
  getCheckLists,
  patchCheckList,
  uploadFile
}
import { API_SERVER } from '../config/constant';
import http from './httpService';

const upcomingEvents = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}company/api/upcoming-events/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error, error.response);
        return false;
    }
};

const getToDos = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/todo/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log('error fetching todo >>', error, error.response);
        return false;
    }
};

const updateToDo = async (id, updateData) => {
    try {
        const { data } = await http.patch(`${API_SERVER}account/api/todo/${id}/`, updateData, http.tokenConfig());
        return data;
    } catch (error) {
        console.log('error fetching todo >>', error, error.response);
        return false;
    }
};

const createToDo = async (createData) => {
    try {
        const { data } = await http.post(`${API_SERVER}account/api/todo/`, createData, http.tokenConfig());
        return data;
    } catch (error) {
        console.log('error fetching todo >>', error, error.response);
        return false;
    }
};

const deleteToDo = async (id) => {
    try {
        await http.delete(`${API_SERVER}account/api/todo/${id}/`, http.tokenConfig());
        return 'success';
    } catch (error) {
        console.log('error fetching todo >>', error, error.response);
        return false;
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    upcomingEvents,
    getToDos,
    deleteToDo,
    createToDo,
    updateToDo
};

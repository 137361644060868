import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import userService from '../../../services/userService';
import { formatToReactSelectOption } from '../../../utils/common';

export default function UsersMultiSelectInput({ value, defaultValue, handleInputChange, label, name, placeholder, disabled }) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(value);

    const fetchOptionsData = async () => {
        if (options.length === 0) {
            setLoading(true);
            const data = await userService.getAllUsersSimple();
            if (data) {
                let formattedData = formatToReactSelectOption(data, 'full_name_or_email');

                setOptions(formattedData);
                setLoading(false);
            }
        }
    };

    const handleChange = (data, optionItem) => {
        if (typeof handleInputChange === 'function') {
            handleInputChange(data, optionItem);
        }
        setSelected(data);
    };

    useEffect(() => {
        if (value && options.length > 0) {
            const selectedOptions = options.filter((opt) => value.includes(opt.id));
            setSelected(selectedOptions);
        }
    }, [value, options.length]);

    useEffect(() => {
        fetchOptionsData();
    }, []);

    return (
        <Form.Group>
            <Form.Label>{label || 'Staff'}</Form.Label>
            <div className="d-flex">
                <Select
                    className={'basic-single w-100'}
                    classNamePrefix="select"
                    defaultValue={defaultValue}
                    value={selected}
                    isDisabled={loading || disabled}
                    isLoading={loading}
                    placeholder={loading ? 'loading...' : placeholder || 'Select Staff'}
                    isClearable={true}
                    isMulti
                    isSearchable={true}
                    name={name || 'staff'}
                    onChange={handleChange}
                    options={options}
                />
            </div>
        </Form.Group>
    );
}

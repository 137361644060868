// action - state management
import {
    ACCOUNT_INITIALIZE,
    CHANGE_LANG,
    LOGIN,
    LOGOUT,
    SET_EMPLOYEES,
    SET_POSITIONS,
    SET_REQUESTED_URL,
    SET_SUBSCRIPTION,
    SET_USER
} from './actions';

export const initialState = {
    token: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    requestedUrl: '',
    employees: [],
    positions: [],
    subscription: null
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE: {
            const { isLoggedIn, user, token } = action.payload;
            if (user.staff_info?.language_code) {
                localStorage.setItem('i18nextLng', user.staff_info.language_code);
            } else {
                localStorage.setItem('i18nextLng', user.company.language_code);
            }
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                token,
                user
            };
        }
        case SET_USER: {
            return { ...state, user: action.payload };
        }
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                user
            };
        }
        case LOGOUT: {
            //I don't think we want this since we use local storage to hold filter states and such
            // localStorage.clear();

            if (action?.payload?.clearLocalStorage) {
                // for the case where an account is being deleted,
                // we also clear localstorage to have the user on a clean slate
                localStorage.clear();
            }
            return {
                ...state,
                isLoggedIn: false,
                token: '',
                user: null
            };
        }

        case CHANGE_LANG: {
            const { language_code, is_dark_mode } = action.payload;

            const userCopy = JSON.parse(JSON.stringify(state.user));

            userCopy.staff_info.language_code = language_code;
            userCopy.staff_info.is_dark_mode = is_dark_mode;

            console.log('The copy', userCopy);

            return {
                ...state,
                user: userCopy
            };
        }

        case SET_REQUESTED_URL: {
            return {
                ...state,
                requestedUrl: action.payload
            };
        }

        case SET_EMPLOYEES:
            return {
                ...state,
                employees: action.payload
            };

        case SET_POSITIONS:
            return {
                ...state,
                positions: action.payload
            };

        case SET_SUBSCRIPTION:
            return {
                ...state,
                subscription: action.payload
            };

        default: {
            return { ...state };
        }
    }
};

export default accountReducer;

import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import userService from '../../../services/userService';
import { formatToReactSelectOption } from '../../../utils/common';
import AddResponsibilityModal from '../../responsibilities/AddResponsibilityModal';

export default function ResponsibilitiesInput({ value, defaultValue, handleInputChange, label, name, canAdd = true }) {
    const [responsibilities, setResponsibilities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(value);

    const fetchResponsibilities = async () => {
        if (responsibilities.length === 0) {
            setLoading(true);
            const pageSize = 30;
            const data = await userService.getResponsibilities(pageSize);
            if (data) {
                const formattedData = formatToReactSelectOption(data.results, 'title');
                setResponsibilities(formattedData);
                setLoading(false);
            }
        }
    };

    const handleChange = (data, options) => {
        if (typeof handleInputChange === 'function') {
            handleInputChange(data, options);
        }
        setSelected(data);
    };

    const updateResponsibilities = (data) => {
        setResponsibilities([data, ...responsibilities]);
    };

    const addSelectedResponsibility = (item) => {
        const data = [...selected, { id: item.id, value: item.id, label: item.title }];
        handleChange(data, { name: 'responsibilities' });
    };

    useEffect(() => {
        if (value) {
            setSelected(value);
        }
    }, [value]);

    useEffect(() => {
        fetchResponsibilities();
    }, []);

    return (
        <Form.Group>
            <Form.Label>{label || 'Responsibilities'}</Form.Label>
            <div className="d-flex">
                <Select
                    className="basic-single w-100"
                    classNamePrefix="select"
                    defaultValue={defaultValue}
                    value={selected}
                    isDisabled={loading}
                    isLoading={loading}
                    placeholder={loading ? 'loading...' : 'Select Responsibilities'}
                    isClearable={true}
                    isMulti
                    isSearchable={true}
                    name={name || 'responsibilities'}
                    onChange={handleChange}
                    options={responsibilities}
                />

                {canAdd && (
                    <AddResponsibilityModal
                        updateResponsibilities={updateResponsibilities}
                        selectResponsibility={addSelectedResponsibility}
                    />
                )}
            </div>
        </Form.Group>
    );
}

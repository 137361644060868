import axios from 'axios';
import { API_SERVER } from '../config/constant';
import {
    ADD_STAT,
    CHANGE_LANG,
    DELETE_STAT,
    GET_ALL_PURCHASE_REQUESTS,
    GET_DIVISIONS,
    GET_MANAGERS,
    GET_MY_PURCHASE_REQUESTS,
    GET_MY_REQUESTS,
    GET_POSITIONS,
    GET_PROFILE,
    GET_PURCHASES_TO_APPROVE,
    GET_PURCHASE_DETAILS,
    GET_PURCHASE_METHOD,
    GET_PURCHASE_SET_A_SIDE,
    GET_PURCHASE_TYPE,
    GET_REQUESTS,
    GET_REQUESTS_FOR_APPROVAL,
    GET_REQUEST_DETAILS,
    GET_STATS_ARCHIVE,
    GET_STATS_LIST,
    GET_TAGS,
    GET_TASK_DETAILS,
    GET_USER_TYPES,
    LOADING_REQUEST_DETAILS,
    REQUEST_DETAILS_ERROR,
    SET_COMPANY,
    UPDATE_STATS_LIST
} from './actions';

import { Stats } from '../services';
import { store } from './index';

const tokenConfig = () => {
    const token = store.getState().account.token;

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
};

const editConfig = () => {
    const token = store.getState().account.token;

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
};

export const getProfile = (id) => (dispatch) => {
    axios
        .get(`${API_SERVER}account/api/staffs/${id}`, tokenConfig())
        .then((res) => {
            console.log('Profile', res);
            dispatch({
                type: GET_PROFILE,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getCompany = (id) => (dispatch) => {
    axios
        .get(`${API_SERVER}company/api/detail-update/${id}`, tokenConfig())
        .then((res) => {
            dispatch({
                type: SET_COMPANY,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getDivisions = (company_id) => (dispatch) => {
    axios
        .get(`${API_SERVER}division/api/`, tokenConfig())
        .then((res) => {
            console.log('The division res', res);
            dispatch({
                type: GET_DIVISIONS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPositions = (company_id) => (dispatch) => {
    axios
        .get(`${API_SERVER}account/api/positions/${company_id}`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_POSITIONS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getManagers = () => (dispatch) => {
    axios
        .get(`${API_SERVER}account/api/staffs/company_users/`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_MANAGERS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getUserTypes = () => (dispatch) => {
    axios
        .get(`${API_SERVER}account/api/user-type/`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_USER_TYPES,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

//Staff Requests

export const getMyRequests = () => (dispatch) => {
    axios
        .get(`${API_SERVER}request/api/list/?status=my_request`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_MY_REQUESTS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getRequestsApproval = () => (dispatch) => {
    axios
        .get(`${API_SERVER}request/api/list/?status=for_approval`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_REQUESTS_FOR_APPROVAL,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getRequests = (admin) => (dispatch) => {
    axios
        .get(`${API_SERVER}request/api/list/${admin ? '' : '?my_all'}`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_REQUESTS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getRequestDetails = (id) => async (dispatch) => {
    dispatch({
        type: REQUEST_DETAILS_ERROR,
        payload: false
    });
    dispatch({
        type: LOADING_REQUEST_DETAILS,
        payload: true
    });
    await axios
        .get(`${API_SERVER}request/api/detail/${id}/`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_REQUEST_DETAILS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: REQUEST_DETAILS_ERROR,
                payload: err?.response?.status
            });
        });
    dispatch({
        type: LOADING_REQUEST_DETAILS,
        payload: false
    });
};

//-----------------------Task----------------

export const getTaskDetails = (id) => (dispatch) => {
    axios
        .get(`${API_SERVER}request/api/task/${id}/`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_TASK_DETAILS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

//Purchase Requests

export const getMyPurchaseRequests = () => (dispatch) => {
    axios
        .get(`${API_SERVER}purchase_requisition/api/list/?purchase_stage=my_request`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_MY_PURCHASE_REQUESTS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getAllPurchaseRequests = () => (dispatch) => {
    axios
        .get(`${API_SERVER}purchase_requisition/api/list/`, tokenConfig())
        .then((res) => {
            console.log('All Requests', res.data);
            dispatch({
                type: GET_ALL_PURCHASE_REQUESTS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPurchasesToApprove = () => (dispatch) => {
    axios
        .get(`${API_SERVER}purchase_requisition/api/list/?purchase_stage=to_approve`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_PURCHASES_TO_APPROVE,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPurchaseDetails = (id) => (dispatch) => {
    axios
        .get(`${API_SERVER}purchase_requisition/api/detail/${id}/`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_PURCHASE_DETAILS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPurchaseMethod = () => (dispatch) => {
    axios
        .get(`${API_SERVER}purchase_requisition/api/purchase-method/`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_PURCHASE_METHOD,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPurchaseType = () => (dispatch) => {
    axios
        .get(`${API_SERVER}purchase_requisition/api/purchase-type/`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_PURCHASE_TYPE,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getPurchaseSetASide = () => (dispatch) => {
    axios
        .get(`${API_SERVER}purchase_requisition/api/set-aside/`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_PURCHASE_SET_A_SIDE,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

//--------------------------------Stats-------------------------

export const getTags = () => (dispatch) => {
    axios
        .get(`${API_SERVER}statistics/api/tags/`, tokenConfig())
        .then((res) => {
            dispatch({
                type: GET_TAGS,
                payload: res.data
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getSingleStatValue = async (id) => {
    const { data } = await axios.get(`${API_SERVER}statistics/api/single-stat/${id}`, tokenConfig());
    return data;
};

export const getStatsList = () => async (dispatch) => {
    const { data } = await axios.get(`${API_SERVER}statistics/api/list-create2/`, tokenConfig());
    const statList = [];
    // for await (let datum of data.splice(0, 3)) {
    //     try {
    //         const stat = await getSingleStatValue(datum.id);
    //         statList.push({ ...datum, converted_values: stat.converted_values, loaded: true });
    //     } catch (ex) {
    //         console.log(ex);
    //     }
    // }
    dispatch({
        type: GET_STATS_LIST,
        payload: [...statList, ...data]
    });
};

export const getStatsArchive = () => async (dispatch) => {
    const { data } = await axios.get(`${API_SERVER}statistics/api/list-create/?archived=true`, tokenConfig());
    const statList = [];
    // for await (let datum of data.splice(0, 3)) {
    //     try {
    //         const stat = await getSingleStatValue(datum.id);
    //         statList.push({ ...datum, converted_values: stat.converted_values, loaded: true });
    //     } catch (ex) {
    //         console.log(ex);
    //     }
    // }
    console.log('archedData', data);
    dispatch({
        type: GET_STATS_ARCHIVE,
        payload: { ...statList, ...data }
    });
};

export const updateStatsList = (data, interval) => async (dispatch) => {
    const stat = await Stats.getStatFilter(data.id, interval);
    console.log("getStatFilter 6")
    //todo add period
    dispatch({
        type: UPDATE_STATS_LIST,
        payload: {
            ...data,
            statvalues: stat.statvalues,
            converted_values: stat.converted_values,
            change: stat.change,
            status: stat.status,
            stat_last_updated: stat.stat_last_updated,
            loaded: true
        }
    });
};

export const deleteStat = (id) => {
    axios.delete(`${API_SERVER}statistics/api/update-delete/${id}`, tokenConfig());
    return {
        type: DELETE_STAT,
        payload: id
    };
};

export const addStat = (data, interval) => async (dispatch) => {
    const stat = await Stats.getStatFilter(data.id, interval);
    console.log("getStatFilter 7")
    //todo add period
    if (!stat) return;
    dispatch({
        type: ADD_STAT,
        payload: { ...data }
    });
};
//--------------------Language-----------------

export const updateLanguage = (id, body) => (dispatch) => {
    axios
        .put(`${API_SERVER}account/api/staffs/${id}/private_info_update/`, body, tokenConfig())
        .then((res) => {
            dispatch({
                type: CHANGE_LANG,
                payload: res.data.staff_info
            });
            if (body.language_code) {
                localStorage.setItem('i18nextLng', body.language_code);
                window.location.reload();
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/agGrid.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { useDispatch } from 'react-redux';
import { BASENAME, DJANGO, changeApiUrl } from './config/constant';
import routes, { renderRoutes } from './routes';
import userService from './services/userService';
import { ACCOUNT_INITIALIZE } from './store/actions';
import {postToFrontEndErrors} from "./services/actionsService";
import debounce from "lodash.debounce";

LicenseManager.setLicenseKey(
    'Using_this_AG_Grid_Enterprise_key_( AG-048786 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Humanagement LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 4 October 2024 )____[v2]_MTcyNzk5NjQwMDAwMA==e206a710510aa81fbe6a72064432cc87'
);

// Load JS
const charge = document.createElement('script');
charge.src = 'https://js.chargebee.com/v2/chargebee.js';
charge.setAttribute(
    'data-cb-site',
    process.env.REACT_APP_CHARGEBEEURL || 'humanagement-test'
);
document.head.appendChild(charge);

const App = () => {
    const dispatcher = useDispatch();
    const data = document.getElementById('user-data').getAttribute('data-props');
    console.log('userdatad', data, data === '{{data}}', data === '{{data}}!');
    // console.log("userdatad2",changeApiUrl,BASENAME)
    const [django, setDjango] = useState(false);

    function getCircularReplacer() {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return "[Circular]";
                }
                seen.add(value);
            }
            return value;
        };
    }


          useEffect(() => {

    const handleChunkLoadError = debounce((event) => {
            if (event?.error && event.error.name === 'ChunkLoadError') {
                // Handle the chunk load error
                console.error('A chunk failed to load:', event.reason);
                // Optionally, force a reload
                window.location.reload();
            } else if (event?.error && event.error.name === 'Unhandled Error') {
                console.error('Unhandled Error!!!:', event.error, event);
                // Reload the page for Unhandled Error
                window.location.reload();
                return <center><h1>There was an error on this page please reload</h1></center>
            } else {
                console.error('Other Error:', event.error, event);
                const reasonString = JSON.stringify(event.error, getCircularReplacer());


                postToFrontEndErrors({
                    error_name: event.reason?.name,
                    message: event.reason?.message,
                    error: {
                        ...event,
                        defaultPrevented: event.defaultPrevented,
                        message: event.reason?.message,
                        stack: event.reason?.stack,
                        type: event.type,
                        reason: reasonString,
                    }
                    // Include any other relevant information
                });
                return <center><h1>There was an error on this page please reload</h1></center>
            }
        }, 500);

        window.addEventListener('unhandledrejection', handleChunkLoadError);
        window.addEventListener('error', handleChunkLoadError);

        return () => {
            window.removeEventListener('unhandledrejection', handleChunkLoadError);
            window.removeEventListener('error', handleChunkLoadError);
        };
    }, []);

    useEffect(() => {
        setDjango(DJANGO);
    }, [DJANGO]);

    useEffect(() => {
        if (data && data !== '{{data}}') {
            (async () => {
                console.log('JSON.parse', data);
                setDjango(true);
                const response = JSON.parse(data);
                changeApiUrl(response.baseUrl);

                const userData = await userService.getProfile(response.id);
                console.log('React Login Success!', userData);
                dispatcher({
                    type: ACCOUNT_INITIALIZE,
                    payload: { isLoggedIn: true, user: userData.data, token: response.token }
                });
            })();
        }
        return () => {
            // dispatcher({ type: LOGOUT });
        };
    }, [data, dispatcher]);

    return (
        <React.Fragment>
            <Router basename={BASENAME}>{renderRoutes(routes(django), django)}</Router>
            <ToastContainer stacked />
        </React.Fragment>
    );
};

export default App;

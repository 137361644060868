import { toast } from 'react-toastify';
import { API_SERVER } from '../config/constant';
import http from './httpService';

const addCompanyLinks = async (id, addData) => {
    try {
        const { data } = await http.post(`${API_SERVER}account/api/add-delete-menu-link/${id}/`, addData, http.tokenConfig());
                toast.success("Link Created")
        return data;

    } catch (error) {
        console.log(error);

        toast.error("Error With Adding Link"+ " " +error.response.request.response, {
        });
        return false;
    }
};

const getCompanyMenuLinks = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}account/api/menu-links/`, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const updateCompanyMenuLink = async (id, formData) => {
    try {
        const { data } = await http.patch(`${API_SERVER}account/api/add-delete-menu-link/${id}/`, formData, http.tokenConfig());
        return data;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const deleteCompanyMenuLinks = async (id) => {
    try {
        await http.delete(`${API_SERVER}account/api/add-delete-menu-link/${id}/`, http.tokenConfig());
        return 'success';
    } catch (error) {
        toast.error('Error deleting menu link ' + JSON.stringify(error?.response?.data));
        return false;
    }
};

export default {
    addCompanyLinks,
    getCompanyMenuLinks,
    updateCompanyMenuLink,
    deleteCompanyMenuLinks
};

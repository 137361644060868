import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {copyGivenValue, dictHasItems} from '../../utils/common';
import divisionService from "../../services/divisionService";
import {UPDATE_ORG_CHART} from "../../store/actions";
import Swal from "sweetalert2";
import CustomTooltip from "../../components/CustomTooltip";
import {SketchPicker} from "react-color";

export default function DivisionForm({ instance, handleSubmit, positionChart, callbackFunc }) {
    const updateOrgChart = useSelector((state) => state.companyReducer.updateOrgChart);
    const dispatch = useDispatch();
    const companyId = useSelector((state) => state.account?.user?.company?.id);
    const Schema = Yup.object().shape({
        division_name: Yup.string().required('This field is required'),
        division_color: Yup.string().required('This field is required'),
        division_order: Yup.string().required('This field is required')
        // division_description: Yup.string().required('This field is required')
    });

    const isRequired = (message) => (value) => !!value ? undefined : message;

    const onSubmit = async (formData, actions) => {
        // send the created card data to the backend
        let createData = {
            ...formData,
            company: companyId
        };
        !formData.division_order && delete createData['division_order'];
        const data = await handleSubmit(createData);

        if (data) {
            // call the callback function if it exists
            typeof callbackFunc === 'function' && callbackFunc(data);
        }
        // update submit to false
        actions.setSubmitting(false);
    };

    const deleteDivision = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this division!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            dangerMode: true,
        })
            .then(async (result) => {
                // Check if the confirm button was clicked
                if (result.value) {
                    await divisionService.deleteCompanyDivisions(instance.id);
                    console.log('Division/Dept deleted');
                    typeof callbackFunc === 'function' && callbackFunc();
                    dispatch({ type: UPDATE_ORG_CHART, payload: !updateOrgChart });
                } else {
                    console.log('Division/Dept deletion cancelled');
                }
            });
    }



    return (
        <Formik
            validationSchema={Schema}
            // validate={validate}
            initialValues={{
                division_name: instance?.division_name || instance?.name || '',
                division_order: instance?.division_order || instance?.order || 1,
                division_color: instance?.division_color || instance?.color || '#0cb2fc',
                division_description: instance?.division_description || instance?.description || ''
            }}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {({ errors, isSubmitting, setFieldValue, values }) => (
                <Form>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="form-group">
                                <label htmlFor="division_name">Division/Dept Name</label>
                                <Field
                                    className="form-control"
                                    name="division_name"
                                    validate={isRequired('This field is required')}
                                    type="text"
                                    placeholder="Enter division name..."
                                />
                                {errors.division_name && <span className="text-danger">{errors.division_name}</span>}
                            </div>
                        </div>
                        {!positionChart && (
                            <div className="col-md-3 col-12">
                                <div className="form-group">
                                    <label htmlFor="division_order"> Order</label>
                                    <Field
                                        className="form-control"
                                        name="division_order"
                                        validate={isRequired('This field is required')}
                                        type="number"
                                        placeholder="Enter division order..."
                                    />
                                    {errors.division_order && <span className="text-danger">{errors.division_order}</span>}
                                </div>
                            </div>
                        )}
                        <div className={positionChart ? 'col-md-6 col-12' : 'col-md-3 col-12'}>
                            <div className="form-group">
                                <label htmlFor="division_color"> Color</label>
                                <CustomTooltip title="Click to copy this vision's Color">
                                            <span className="cursor-pointer" onClick={()=>copyGivenValue(instance.division_color)}>
                                                {/*Copy Link */}
                                                <i className=" ml-2 fa fa-clone" />
                                            </span>
                                </CustomTooltip>
                                <Field
                                    className="color-input"
                                    name="division_color"
                                    validate={isRequired('This field is required')}
                                    type="color"
                                />
                                {errors.division_color && <span className="text-danger">{errors.division_color}</span>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="division_description">Description</label>
                                <Field
                                    className="form-control"
                                    name="division_description"
                                    // validate={isRequired('This field is required')}
                                    component="textarea"
                                    placeholder="Enter description..."
                                />
                                {errors.division_description && <span className="text-danger">{errors?.division_description}</span>}
                            </div>
                        </div>
                    </div>

                    <Button type="submit" disabled={isSubmitting || dictHasItems(errors)}>
                        {isSubmitting ? 'Saving....' : 'Save'}
                    </Button>

                    <Button onClick={deleteDivision} variant={'danger'} hidden={!instance} disabled={isSubmitting}>
                        Delete
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

// import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import 'react-tippy/dist/tippy.css'
import {
  Tooltip as ReactTippy
} from 'react-tippy';

const CustomTooltip = ({title, position, children, interactive, html, ...rest }) => (
//   <OverlayTrigger
//     placement="bottom"
//     defaultShow={false}
//     onHide={false}
//     {...rest}
//     overlay={<BootstrapTooltip>{rest.title}</BootstrapTooltip>}
//   >
//     {children}
//   </OverlayTrigger>


<ReactTippy
        html={html}
  title={title}
  position={position||"top"}
  hideDelay={5000}
  interactive={interactive}
  arrow={true}
  sticky={true}
  trigger="mouseenter"
  interactiveBorder={10}
  animation={"shift"}
>
     {children}
  </ReactTippy>
    );

export default CustomTooltip;
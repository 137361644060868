import {GET_MY_PURCHASE_REQUESTS, 
        GET_ALL_PURCHASE_REQUESTS, 
        GET_PURCHASES_TO_APPROVE,
        GET_PURCHASE_DETAILS,
        GET_PURCHASE_METHOD,
        GET_PURCHASE_SET_A_SIDE,
        GET_PURCHASE_TYPE} from './actions';

export const initialState = {
    myRequests: [],
    toApprove: [],
    allRequests: [],
    purchaseMethod: [],
    purchaseType: [],
    purchaseSetASide: [],
    purchaseRequest: {}
};

const requestReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_PURCHASE_REQUESTS:
            return {
                ...state,
                myRequests: action.payload
            };

        case GET_PURCHASES_TO_APPROVE:
            return {
                ...state,
                toApprove: action.payload
            };

        case GET_ALL_PURCHASE_REQUESTS:
            return {
                ...state,
                allRequests: action.payload
            };

        case GET_PURCHASE_DETAILS:
            return {
                ...state,
                purchaseRequest: action.payload
            };

        case GET_PURCHASE_TYPE:
            return {
                ...state,
                purchaseType: action.payload
            };

        case GET_PURCHASE_METHOD:
            return {
                ...state,
                purchaseMethod: action.payload
            };

        case GET_PURCHASE_SET_A_SIDE:
            return {
                ...state,
                purchaseSetASide: action.payload
            };
    

        default:
            return state;
    }
}

export default requestReducer;
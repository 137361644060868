import {GET_PROFILE, EDIT_PUBLIC_PROFILE, GET_POSITIONS, GET_DIVISIONS, GET_MANAGERS} from './actions';

export const initialState = {
    publicProfile: {},
    positions: [],
    divisions: [],
    managers: [],
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFILE:
            return {
                ...state,
                publicProfile: action.payload
            };

        case EDIT_PUBLIC_PROFILE:
            return {
                ...state,
                publicProfile: action.payload
            };
        
        case GET_POSITIONS:
            return {
                ...state,
                positions: action.payload
            };

        case GET_DIVISIONS:
            return {
                ...state,
                divisions: action.payload
            };

        case GET_MANAGERS:
            return {
                ...state,
                managers: action.payload
            };

        default:
            return state;
    }
}

export default profileReducer;
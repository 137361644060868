
import React, {useCallback, useContext, useState} from 'react';

import { useSelector } from 'react-redux';
import { API_SERVER } from '../config/constant';
// import { store } from '../store';
import { Editor } from '@tinymce/tinymce-react';
import {ConfigContext} from "../contexts/ConfigContext";


// important! when this editor is inside a modal, ensure that modal has enforceFocus={false} and backdrop="static" set it that modals props

export default function RichTextEditor({ value, onChange, disablePaste, readOnly, simple, placeholder }) {
    const company = useSelector((state) => state.account?.user?.company);
    const { layoutType } = useContext(ConfigContext).state;

    const handleStopPropagation = useCallback((e) => {
        e.stopPropagation();
    }, []);

    const ai_request = (request, respondWith) => {

        const modifiedPrompt = `
  Answer the question based on the context below.\\n
  You are an ai assistant who's purpose is to help with generating and creating official 
  company policies, hats (a write up of how to do something), job write ups, quizzes, SOP's and other training material.  \\n
  When referring to a company name, use "${company.company_name}". \\n
  Your answer should only be html that would be directly inserted into tinymce. \\n
  Your answer should only contain the text in tags it it should not start with "html" 
  and should end with closing the tag  \n
  Never respond with just the title unless specifically asked for \n
  if generating a title or heading always use h1 tags. and utilize the other h tags where applicable
   ${request.system.join('\n')}\n\nContext: "${request.context}"\n\nQuestion:
    "${request.query}"\n\nAnswer:`;

        const openAiOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${api_key}`
            },
            body: JSON.stringify({
                // model: 'gpt-3.5-turbo-0125',
                model: 'gpt-3.5-turbo',
                temperature: 0.7,
                max_tokens: 800,
                messages: [{ role: 'user', content: modifiedPrompt }],
            })
        };
        respondWith.string((signal) => window.fetch(`${API_SERVER}company/api/openai-proxy/`, { signal, ...openAiOptions })
            .then(async (response) => {
                if (response) {
                    const data = await response.json();
                    if (data.error) {
                        throw new Error(`${data.error.type}: ${data.error.message}`);
                    } else if (response.ok) {
                        // Extract the response content from the data returned by the API
                        return data?.choices[0]?.message?.content?.trim();
                    }
                } else {
                    throw new Error('Failed to communicate with the ChatGPT API');
                }
            })
        );
    };



    const tinyMCEConfig = {
        // readonly: readOnly ? 1 : 0,
        disabled: readOnly,
        editable_root: !readOnly,
        selector: 'textarea',  // change this value according to your HTML
        skin: readOnly? 'borderless':'oxide',       // Name of the skin
        // content_css: 'borderless',  // Name of the content skin
        // This is to deal with files,
        // https://www.tiny.cloud/docs/tinymce/6/file-image-upload/#interactive-example
        // todo above is the doc. it's commented out for now but needs to be fixed
        // file_picker_types: 'file media',
        // file_picker_callback: (cb, value, meta) => {
        //     const input = document.createElement('input');
        //     input.setAttribute('type', 'file');
        //     input.setAttribute('accept', 'video/*,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation');
        //
        //     input.addEventListener('change', (e) => {
        //         const file = e.target.files[0];
        //
        //         const reader = new FileReader();
        //         reader.addEventListener('load', () => {
        //             /*
        //               Note: Now we need to register the blob in TinyMCEs image blob
        //               registry. In the next release this part hopefully won't be
        //               necessary, as we are looking to handle it internally.
        //             */
        //             const id = 'blobid' + (new Date()).getTime();
        //             const blobCache =  false
        //             const base64 = reader.result.split(',')[1];
        //             const blobInfo = blobCache.create(id, file, base64);
        //             blobCache.add(blobInfo);
        //
        //             /* call the callback and populate the Title field with the file name */
        //             cb(blobInfo.blobUri(), { title: file.name });
        //         });
        //         reader.readAsDataURL(file);
        //     });
        //
        //     input.click();
        // },
        // block_unsupported_drop: true,
        statusbar: !readOnly,
        setup: function (editor) {
        editor.on('click', function (e) {
            e.stopPropagation();
        });
    },
        menubar: !readOnly,
        autoresize_bottom_margin: 0,
        plugins:
            ` advlist file autoresize autolink lists checklist link image editimage print preview anchor
            searchreplace visualblocks code fullscreen media mediaembed emoticons formatpainter
            insertdatetime media table paste code help powerpaste wordcount ai`,
        toolbar: readOnly ? false : `undo redo | formatselect aidialog aishortcuts | bold italic backcolor | 
            // alignleft aligncenter alignright alignjustify formatpainter | file image 
            bullist numlist checklist outdent indent | emoticons removeformat | help`,
        placeholder: placeholder || 'Please add your article here...',
        ai_request,
        branding: false,
        min_height: readOnly || 400,
        paste_data_images: true,
        // images_upload_url: `${API_SERVER}knowledgebase/upload_file/tinymce/`,
        // images_upload_handler: function (blobInfo, success, failure) {
        //     // Define how to handle file uploads here
        // },
        content_style: layoutType === 'dark'?`
        body { color: #f8f8f8; border: none !important; background: #262729} /* Default text color */
        p { color: #f8f8f8; } /* Paragraph text color */
        h1, h2, h3, h4, h5, h6 { color: #f8f8f8; } /* Heading text color */
        .custom-class { color: #f8f8f8; } /* Custom class text color */
        ${readOnly} ? '.tox-tinymce { border: none !important; }' : ''
    `: `${readOnly} ? '.tox-tinymce { border: none !important; }' : ''`
        ,
        // setup: function(editor) {
        //     editor.on('change', function(e) {
        //         onChange(editor.getContent());
        //     });
        // }
    };

    return (
        <div className={readOnly? 'readOnly text-editor-component':'text-editor-component'} onClick={handleStopPropagation}>
            {readOnly ?

                <div class="mce-content-body" dangerouslySetInnerHTML={{__html: value}}/>
                :
                <Editor
                    value={value} // Use value prop for controlled components
                    apiKey='4x7nzqhqibirfrmbzep9uy2ixg57pxrasnixye30y7risj53'
                    init={tinyMCEConfig}
                    onEditorChange={(content, editor) => !readOnly && onChange(content)}

                />
            }
        </div>
    );
}




import { defaultFormatUtc } from 'moment'
import avatarPersonnel from '../assets/images/user/avatar-2.jpg'
import avatarFemale from '../assets/images/user/avatar-1.jpg'
import avatarMale from '../assets/images/user/avatar-4.jpg'

export const formatManager=(data)=>{
    console.log('formatManager',{ln:data})
    let managers=[]
    for(let datum of data){
        let isManagerExist=managers.filter(man=>man.id==datum.manager?.id)
        if(isManagerExist.length==0){
            if(datum.manager===null){
                managers=[...managers,{
                    ...datum,
                    juniors:[]
                }]
            }
            else{
                managers=[...managers,{
                       ... datum.manager,
                       juniors:[{
                           fullName:datum.first_name + " "+ datum.last_name,
                           id:datum.id,
                           position:datum.position.name,
                           gender:datum.gender,
                           divisionColor:datum.staff_division.division_color,
                           divisionName:datum.staff_division.division_name
                       }]
                    }]
            }
        }
        else{
         managers=managers?.map(manager=>manager.id==datum.manager?.id?({
             ...manager,
             juniors:[...manager.juniors,{
                 fullName:datum.first_name + " "+ datum.last_name,
                 id:datum.id,
                 position:datum.position.name,
                 gender:datum.gender,
                 divisionColor:datum.staff_division.division_color,
                 divisionName:datum.staff_division.division_name
            }]
         }):manager)
        }
    }
    return managers.filter(mn=>mn.id!==undefined)
}

const formatPerson=(obj,position,totalReports,photo)=>{
    console.log("obj", obj, position)
return   {
    id:obj.id,
    person: {
        id: 100,
        avatar:photo?photo:obj.gender==='FEMALE'?avatarFemale:obj.gender==='OTHER'?avatarPersonnel:avatarMale,
        department: obj.divisionName,
        name: obj.first_name + " " + obj.last_name,
        title: position,
        totalReports,
      },
      hasChild: true,
      hasParent: true,
      children: []
}
}

const formatJunior=(obj,position,managerId,totalReports,photo)=>{


    return   {
        id:obj.id,
        managerId:managerId,
        person: {
            id: 100,
            avatar:photo?photo:obj.gender==='FEMALE'?avatarFemale:obj.gender==='OTHER'?avatarPersonnel:avatarMale,
            department: obj.divisionName,
            name: obj.fullName,
            title: position,
            totalReports,
          },
          hasChild: true,
          hasParent: true,
          children: []
    }
    }

export const expandTree=(root,trees)=>{   
    let tmpTree=[]
    for(let tree of trees){
        let filteredTree=trees.filter(datum=>datum.managerId==tree.id)
        tmpTree.push(
            {  
             ...tree,
             _children:null,
             depth:tree.depth?tree.depth+1:0,
             children:[...filteredTree]
           }
        )

    }
    // console.log({
    //     tmpTree:{
    //         ...root,
    //         depth:0,
    //         _children:null,
    //         children:tmpTree.filter(datum=>datum.managerId==root.id)
    //     }
    // })
    return  {
        ...root,
        depth:0,
        children:tmpTree.filter(datum=>datum.managerId==root.id)
    }
}

export const createTree=(managers)=>{
    console.log("managets",{managers})
    let trees=[]
    for(let manager of managers){
        // trees=trees?.map(tree=>tree.id==manager.id?
        trees=[...trees,
        {
         ... formatPerson(manager,manager.position?.name,manager.juniors?.length,manager.photo),
        //  children:manager.juniors?.map(junior=>formatJunior(junior,junior.position,manager.id,junior.juniors?.length))
        },
        ...manager.juniors?.map(junior=>formatJunior(junior,junior.position,manager.id,junior.juniors?.length,junior.photo))
        ]

    }
    const filteredTree=trees.reduce((total,tree)=>{
        const item=total.find(el => el.id === tree.id);
        if(!item || !tree.managerId) return [...total,tree]
        item.managerId=tree.managerId
        if(!item.position) item.person.title=tree.person.title
        // if(!item.children?.length) item.children=tree.children
        return total
    },[])
    // const expandedTree=expandTree(filteredTree)
    // console.log({
    //     expandedTree
    // })
    return filteredTree
}
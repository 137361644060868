import { Skeleton } from '@material-ui/lab';
import React from 'react';

export default function ListSkeletonLoader({ items, height }) {
    const listItems = items || 5;
    const skeletonHeight = height || 35;

    return (
        <div>
            {[...Array(listItems)]?.map((_, index) => (
                <Skeleton animation="wave" height={skeletonHeight} key={index} />
            ))}
        </div>
    );
}

export function AcknowledgementListSkeletonLoader({ items }) {
    const listItems = items ? items : 5;

    return (
        <div>
            {[...Array(listItems)]?.map((_, index) => (
                <div className="mb-3" key={index}>
                    <Skeleton animation="wave" height={40} />
                    <Skeleton animation={false} height={20} width={'50%'} />
                </div>
            ))}
        </div>
    );
}

export function CardListSkeletonLoader({ items, height }) {
    const listItems = items ? items : 5;
    const itemHeight = height ? height : 120;

    return (
        <div>
            {[...Array(listItems)]?.map((_, index) => (
                <Skeleton animation="wave" height={itemHeight} key={index} />
            ))}
        </div>
    );
}

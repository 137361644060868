import { GET_TAGS, GET_STATS_LIST, GET_STATS_ARCHIVE, UPDATE_STATS_LIST, DELETE_STAT, ADD_STAT } from './actions';

export const initialState = {
    tags: [],
    statsList: [],
    statsArchive: [],
    tempStat: [],
    nextPage: null,
    previousPage: null
};

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TAGS:
            return {
                ...state,
                tags: action.payload
            };
        // console.log("statsArchive2",...state,action.payload)
        case GET_STATS_LIST:
            return {
                ...state,
                statsList: action.payload,
                nextPage: action.next,
                previousPage: action.previous
            };
        case GET_STATS_ARCHIVE:
            console.log('statsArchive1', action.payload);
            return {
                ...state,
                statsArchive: action.payload
            };
        case 'GET_TEMP_STATS_LIST':
            return {
                ...state,
                tempStat: action.payload
            };
        case ADD_STAT:
            return {
                ...state,
                statsList: [action.payload, ...state.statsList]
            };
        case UPDATE_STATS_LIST:
            return {
                ...state,
                statsList: state.statsList?.map((stats) => (stats.id == action.payload.id ? action.payload : stats))
            };
        case DELETE_STAT:
            return {
                ...state,
                statsList: state.statsList.filter((stats) => stats.id != action.payload)
            };
        case 'SET_PAGINATED_STATS':
            return {
                ...state,
                statsList: [...state.statsList, ...action.statsList],
                nextPage: action.next,
                previousPage: action.previous
            };
        default:
            return state;
    }
};

export default companyReducer;

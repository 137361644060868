import React, { useState } from 'react';
import { Button, Modal, } from 'react-bootstrap';
import ArticleTypeForm from './Forms/ArticleTypeForm';
import CustomTooltip from "../CustomTooltip";

export default function EditArticleTypeModal({ instance, callbackFunc, clearButton }) {
    const [modalShow, setModalShow] = useState(false);

    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true);

    const handleAddCallback = (data) => {
        typeof callbackFunc === 'function' && callbackFunc(data);
        handleCloseModal();
    };



    return (
        <>
            {clearButton ?
                <i className="feather icon-edit" style={{ cursor: 'pointer' }} onClick={handleShowModal}/>
                :
                <CustomTooltip title="Edit Article type">
                    <Button className="btn btn-sm btn-icon ml-1" type="button" style={{ height: 42.8 }} onClick={handleShowModal}>
                        {' '}<i className="feather icon-edit" />
                    </Button>
                </CustomTooltip>
            }

            <Modal backdrop="static" show={modalShow} onHide={handleCloseModal} >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Article Type: {instance?.type}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ArticleTypeForm instance={instance} handleCloseModal={handleCloseModal} callbackFunc={handleAddCallback} />
                </Modal.Body>
            </Modal>
        </>
    );
}

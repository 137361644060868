import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ArticleTypeForm from './Forms/ArticleTypeForm';
import CustomTooltip from "../CustomTooltip";

export default function AddArticleTypeModal({ callbackFunc }) {
    const [modalShow, setModalShow] = useState(false);

    const handleCloseModal = () => setModalShow(false);
    const handleShowModal = () => setModalShow(true);

    const handleAddCallback = (data) => {
        typeof callbackFunc === 'function' && callbackFunc(data);
        handleCloseModal();
    };

    return (
        <>
            <CustomTooltip title="Create New Article type">
                <Button className="btn btn-sm btn-icon mx-0" type="button" style={{ height: 42.8 }} onClick={handleShowModal}>
                    {' '}
                    <i className="feather icon-plus fa-lg mx-0"> </i>
                </Button>
            </CustomTooltip>

            <Modal backdrop="static" show={modalShow} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Article Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ArticleTypeForm handleCloseModal={handleCloseModal} callbackFunc={handleAddCallback} />
                </Modal.Body>
            </Modal>
        </>
    );
}

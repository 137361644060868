import React from 'react';
import { Button } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import userService from '../../services/userService';
import { useSelector } from 'react-redux';

export default function ResponsibilityForm({ updateResponsibilities, selectResponsibility, closeModalFunc }) {
    const loggedInUserId = useSelector((state) => state.account.user.id);
    // const companyId = useSelector((state) => state.account?.user?.company?.id);
    const Schema = Yup.object().shape({
        title: Yup.string().required('This field is required'),
        description: Yup.string().required('This field is required')
    });

    const isRequired = (message) => (value) => !!value ? undefined : message;

    const onSubmit = async (formData, actions) => {
        // send the created card data to the backend
        const createData = {
            title: formData.title,
            description: formData.description,
            assigned_to: null,
            created_by: loggedInUserId,
            responsibility_articles: []
        };
        const data = await userService.createResponsibility(createData);

        if (data) {
            // update the responsibilities to include the newly added responsibility
            typeof updateResponsibilities === 'function' && updateResponsibilities(data);

            // select a responsibility to an input field after it has been created
            typeof selectResponsibility === 'function' && selectResponsibility(data);

            // update submit to false
            actions.setSubmitting(false);

            // close a modal if the form was embedded in a modal
            typeof selectResponsibility === 'function' && closeModalFunc();
        } else {
            actions.setSubmitting(false);
        }
    };

    return (
        <Formik
            validationSchema={Schema}
            // validate={validate}
            initialValues={{
                title: '',
                description: ''
            }}
            onSubmit={onSubmit}
        >
            {({ errors, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <label htmlFor="title">Name Of Responsibility</label>
                        <Field
                            className="form-control"
                            name="title"
                            validate={isRequired('This field is required')}
                            type="text"
                            placeholder="Enter title..."
                        />
                        {errors.title && <span className="text-danger">{errors.title}</span>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <Field
                            className="form-control"
                            name="description"
                            validate={isRequired('This field is required')}
                            component="textarea"
                            placeholder="Enter description..."
                        />
                        {errors.description && <span className="text-danger">{errors.description}</span>}
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="assigned_to">Assign To</label>
                        <Field as="select" name="assinged_to" className="form-control">
                            <option value="red">Kev</option>
                            <option value="green">Kevv</option>
                            <option value="blue">Blue</option>
                        </Field>
                    </div> */}
                    <Button type="submit" disabled={false}>
                        {isSubmitting ? 'Submitting....' : 'Submit'}
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import companyService from '../../../services/companyService';
import { setUser } from '../../../store/accountActions';
import { setCompany } from '../../../store/companyActions';
import ListSkeletonLoader from '../../Loader/Skeleton/ListSkeletonLoader';

const SelectActiveFeature = ({ activeApps, loadingCompanyInfo, updateCompanyCallback, hideHeader, waitToSend }) => {
    const [companyActiveFeatures, setCompanyActiveFeatures] = useState([]);
    //double param is set here so that in the case of wait to send we can handle this all locally.
    const [localActiveApps, setLocalActiveApps] = useState(activeApps);
    const user = useSelector((state) => state.account.user);
    const dispatch = useDispatch();

    useEffect(() => {
        setLocalActiveApps(activeApps);
    }, [activeApps]);

    const getCompanyActiveFeatures = async () => {
        const data = await companyService.getCompanyActiveFeatures();
        if (data) {
            setCompanyActiveFeatures(data);
        }
    };

    useEffect(() => {
        getCompanyActiveFeatures();
    }, []);

    const handleCheckboxChange = async (featureId) => {
        const updatedActiveApps = {
            active_app: localActiveApps.includes(featureId)
                ? localActiveApps.filter((appId) => appId !== featureId)
                : [...localActiveApps, featureId]
        };
        console.log({ updatedActiveApps });
        if (updatedActiveApps?.active_app?.length === 0) {
            toast.warning('At Lease One Feature Must Stay Active');
        } else {
            const data = await companyService.updateCompany(user.company.id, updatedActiveApps);

            if (data) {
                typeof updateCompanyCallback === 'function' && updateCompanyCallback(); // Fetch the updated active apps from the server
                let userCopy = { ...user, company: data };

                toast.success('Active apps updated successfully');
                dispatch(setCompany(data));

                //this function is for when updating enable features on the menu to prevent
                //reloading the modal, we wait until this is hidden in order to update.
                // the dispatch in this case is sent on hide
                if (!!waitToSend) {
                    setLocalActiveApps(data.active_app);
                    waitToSend({ userCopy: userCopy, data: data });
                } else {
                    dispatch(setUser(userCopy));
                }
            }
        }
    };

    return (
        <div id="Activate-Features">
            {!hideHeader && (
                <div className="card-header bd-example-modal-lg">
                    <h5>Select active Features</h5>
                    <div className="card-header-right">
                        <div className="btn-group card-option">{/* Dropdown menu options */}</div>
                    </div>
                </div>
            )}
            <div className="card-block px-4 py-3">
                {loadingCompanyInfo ? (
                    <ListSkeletonLoader items={4} height={70} />
                ) : (
                    <div className="row">
                        {companyActiveFeatures?.map((feature) => {
                            const isActive = localActiveApps.includes(feature.id);

                            return (
                                <div className="col-sm-6" key={feature.id}>
                                    <div className="d-flex justify-content-between">
                                        <p style={{ textTransform: 'capitalize' }}>{feature.app_name.replace('_', ' ')}</p>
                                        <div>
                                            <label>Hidden</label>
                                            <div className="switch d-inline m-r-10">
                                                <input
                                                    className=" app_activator"
                                                    name={feature.app_name}
                                                    type="checkbox"
                                                    id={feature.app_name}
                                                    checked={isActive}
                                                    onChange={() => handleCheckboxChange(feature.id)}
                                                />
                                                <label htmlFor={feature.app_name} className="cr" />
                                            </div>
                                            <label>Visible</label>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectActiveFeature;

import axios from 'axios';
import { toast } from 'react-toastify';
import { API_SERVER } from '../config/constant';
import http from './httpService';

const createFreePlan = async (data) => {
    try {
        const res = await axios.post(`${API_SERVER}subscription/api/create-free-plan/`, data, http.tokenConfig());
        toast.success(res.data.message);
        return res.data;
    } catch (err) {
        // showAxiosErrorNotification(err?.response);
        toast.error('An error occurred while creating plan ' + JSON.stringify(err.response.data));
        console.error('An error occurred creating plan >>>', err, err.response);
        return false; // Or you can throw the error here or return an object with some default values
    }
};

const getMySubscriptionDetails = async () => {
    try {
        const { data } = await http.get(`${API_SERVER}subscription/api/my-subscription/`, http.tokenConfig());
        return data;
    } catch (err) {
        console.log('error is >>>', err?.response);
        return false;
    }
};

const updateSubscription = async (data) => {
    try {
        const res = await http.patch(`${API_SERVER}subscription/api/my-subscription-update/`, data, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.log('error is >>>', err?.response);
        return false;
    }
};

const createChargebeePortalSession = async (data) => {
    try {
        const res = await http.post(`${API_SERVER}subscription/api/create-chargebee-portal-session/`, data, http.tokenConfig());
        return res.data;
    } catch (err) {
        console.log('Error creating portal session', err?.response);
        return false;
    }
};

const checkoutRedirect = async (data) => {
    try {
        const res = await http.post(`${API_SERVER}subscription/api/checkout-redirect/`, data, http.tokenConfig());
        return res.data;
    } catch (err) {
        toast.error('Could not activate your plan, please contact support'+ JSON.stringify(err?.response?.data));
        console.log('Error creating portal session', err?.response);
        return false;
    }
};
export default {
    createFreePlan,
    getMySubscriptionDetails,
    updateSubscription,
    createChargebeePortalSession,
    checkoutRedirect
};
